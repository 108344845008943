import React from 'react';
import { useLocation } from 'react-router-dom';
import { MathJaxContext } from 'better-react-mathjax';
import { TableFromFile } from './Tsv_parser.jsx';
import { HashLink } from 'react-router-hash-link';
import ReleasesTables from './ReleaseTable.jsx';

function jaccardGda() {
  return (
    <math display="block">
      <mrow>
        <msub>
          <mi mathvariant="italic">Jaccard</mi>
          <mrow>
            <mspace width="0.167em" />
            <mi>G</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mrow>
            <msub>
              <mi>G</mi>
              <mn>1</mn>
            </msub>
            <mo>∩</mo>
            <msub>
              <mi>G</mi>
              <mn>2</mn>
            </msub>
          </mrow>
          <mrow>
            <mi>G</mi>
            <mn>1</mn>
            <mo>∪</mo>
            <mi>G</mi>
            <mn>2</mn>
          </mrow>
        </mfrac>
      </mrow>
    </math>
  );
}

function jaccardVda() {
  return (
    <math display="block">
      <mrow>
        <msub>
          <mi mathvariant="italic">Jaccard</mi>
          <mrow>
            <mspace width="0.167em" />
            <mi>V</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mrow>
            <msub>
              <mi>V</mi>
              <mn>1</mn>
            </msub>
            <mo>∩</mo>
            <msub>
              <mi>V</mi>
              <mn>2</mn>
            </msub>
          </mrow>
          <mrow>
            <mi>V</mi>
            <mn>1</mn>
            <mo>∪</mo>
            <mi>V</mi>
            <mn>2</mn>
          </mrow>
        </mfrac>
      </mrow>
    </math>
  );
}

function gdaScore() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <msub>
          <mi>S</mi>
          <mrow>
            <mi mathvariant="italic">GDA</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mi>C</mi>
        <mo>+</mo>
        <mi>M</mi>
        <mo>+</mo>
        <mi>I</mi>
        <mo>+</mo>
        <mi>L</mi>
        <mo>+</mo>
        <mi>T</mi>
      </mrow>
    </math>
  );
}

function gdaScore1() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>C</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.7</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>4</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>

            <mtr>
              <mtd>
                <mn>0.6</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>4</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.5</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>3</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.45</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>2</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.4</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore2() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>M</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.1</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">sourcej</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.05</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">sourcej</mi>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore3() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>I</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.05</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>k</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>0</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore4() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>L</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.4</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>99</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.3</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>9</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.2</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.1</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore5() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>T</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.1</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi>c</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>0</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi>c</mi>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>0</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function DiseaseSpecificityIndex() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi mathvariant="italic">DSI</mi>
        <mo>=</mo>
        <mfrac>
          <mrow>
            <msub>
              <mi mathvariant="italic">log</mi>
              <mn>2</mn>
            </msub>
            <mo stretchy="false" form="prefix">
              (
            </mo>
            <mfrac>
              <msub>
                <mi>N</mi>
                <mi>d</mi>
              </msub>
              <msub>
                <mi>N</mi>
                <mi>T</mi>
              </msub>
            </mfrac>
            <mo stretchy="false" form="postfix">
              )
            </mo>
          </mrow>
          <mrow>
            <msub>
              <mi mathvariant="italic">log</mi>
              <mn>2</mn>
            </msub>
            <mo stretchy="false" form="prefix">
              (
            </mo>
            <mfrac>
              <mn>1</mn>
              <msub>
                <mi>N</mi>
                <mi>T</mi>
              </msub>
            </mfrac>
            <mo stretchy="false" form="postfix">
              )
            </mo>
          </mrow>
        </mfrac>
      </mrow>
    </math>
  );
}

function DiseasePleiotropyIndex() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi mathvariant="italic">DPI</mi>
        <mo>=</mo>
        <mo stretchy="false" form="prefix">
          (
        </mo>
        <mfrac>
          <msub>
            <mi>N</mi>
            <mrow>
              <mi mathvariant="italic">dc</mi>
            </mrow>
          </msub>
          <msub>
            <mi>N</mi>
            <mrow>
              <mi mathvariant="italic">TC</mi>
            </mrow>
          </msub>
        </mfrac>
        <mo stretchy="false" form="postfix">
          )
        </mo>
      </mrow>
    </math>
  );
}

function vdaScore() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <msub>
          <mi>S</mi>
          <mrow>
            <mi mathvariant="italic">VDA</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mi>C</mi>
        <mo>+</mo>
        <mi>L</mi>
      </mrow>
    </math>
  );
}

function vdaScore1() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>C</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.7</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>{'>'}</mo>
                <mn>1</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.6</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function vdaScore2() {
  return (
    <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
      <mi>L</mi>
      <mo>=</mo>
      <mrow>
        <mo stretchy="true" form="prefix">
          &#123;
        </mo>
        <mtable columnalign="left left" rowspacing=".2em" columnspacing="1em" displaystyle="false">
          <mtr>
            <mtd>
              <mn>0.3</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <mi>p</mi>
                  <mi>u</mi>
                  <mi>b</mi>
                  <mi>s</mi>
                </mrow>
              </msub>
              <mo>&gt;</mo>
              <mn>9</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0.2</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <mi>p</mi>
                  <mi>u</mi>
                  <mi>b</mi>
                  <mi>s</mi>
                </mrow>
              </msub>
              <mo>&gt;</mo>
              <mn>1</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0.1</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <mi>p</mi>
                  <mi>u</mi>
                  <mi>b</mi>
                  <mi>s</mi>
                </mrow>
              </msub>
              <mo>=</mo>
              <mn>1</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0</mn>
            </mtd>
            <mtd>
              <mi>o</mi>
              <mi>t</mi>
              <mi>h</mi>
              <mi>e</mi>
              <mi>r</mi>
              <mi>w</mi>
              <mi>i</mi>
              <mi>s</mi>
              <mi>e</mi>
            </mtd>
          </mtr>
        </mtable>
        <mo fence="true" stretchy="true"></mo>
      </mrow>
      <mspace linebreak="newline" />
    </math>
  );
}

function evidenceIndex() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi mathvariant="italic">EI</mi>
        <mo>=</mo>
        <mfrac>
          <msub>
            <mi>N</mi>
            <msub>
              <mrow>
                <mi mathvariant="italic">pubs</mi>
              </mrow>
              <mo>+</mo>
            </msub>
          </msub>
          <msub>
            <mi>N</mi>
            <msub>
              <mrow>
                <mi mathvariant="italic">pubs</mi>
              </mrow>
              <mrow>
                <mi mathvariant="italic">total</mi>
              </mrow>
            </msub>
          </msub>
        </mfrac>
      </mrow>
    </math>
  );
}

function AboutContent() {
  const location = useLocation();
  const hash = location.hash;
  const possibleMetricsHash = [
    '#metrics',
    '#pleiotropy',
    '#specificity',
    '#evindex',
    '#gdaScore',
    '#vdaScore',
  ];
  const possibleSourcesHash = ['#sources', '#section11', '#section12', '#section13'];
  const possibleAttributesHash = [
    '#attributes',
    '#section41',
    '#section42',
    '#section43',
    '#section44',
    '#section45',
    '#section46',
    '#section47',
  ];

  const hashlinkBootstrapClasses = 'mb-1 dropdown-item text-wrap';

  return (
    <MathJaxContext>
      <section className="container">
        <div className=" txt-contenido">
          <h1 className="firstHiddenTitle">Information about database statistics, attributes and metrics.</h1>
          <span>
            <img
              className="align-bottom mr-3"
              src="../../../static/images/LOGO_DISGENET_SIMPLE.svg"
              alt="logo disgenet"
              style={{
                width: '200px',
              }}
            />
          </span>
          <div className="pl-2">
            <p></p>
          </div>
          <div className="row txt-contenido mt-4 border-top border-bottom pt-4 pb-4">
            <div className="col-md-3">
              <div className="nav p-0 dropdown-menu-inline dropdown-menu-rounded dropdown-menu-hover-primary">
                <h5>Statistics</h5>
                <HashLink
                  to="#gdaStatistics"
                  data-toggle="tab"
                  data-target="#gdaStatistics"
                  className={`${hashlinkBootstrapClasses} ${
                    hash === '' || hash === '#gdaStatistics' ? 'active' : ''
                  }`}
                >
                  {' '}
                  DISGENET statistics{' '}
                </HashLink>
                <HashLink
                  to="#gene_type"
                  data-toggle="tab"
                  data-target="#gene_type"
                  className={`${hashlinkBootstrapClasses} ${hash === '#gene_type' ? 'active' : ''}`}
                >
                  {' '}
                  Gene types{' '}
                </HashLink>
                <HashLink
                  to="#variant_type"
                  data-toggle="tab"
                  data-target="#variant_type"
                  className={`${hashlinkBootstrapClasses} ${hash === '#variant_type' ? 'active' : ''}`}
                >
                  Variant types
                </HashLink>

                <h5 className="mt-3">Data</h5>
                <HashLink
                  to="#schema"
                  data-toggle="tab"
                  data-target="#schema"
                  className={`${hashlinkBootstrapClasses} ${hash === '#schema' ? 'active' : ''}`}
                >
                  {' '}
                  Schema{' '}
                </HashLink>
                <HashLink
                  to="#sources"
                  data-toggle="tab"
                  data-target="#sources"
                  className={`${hashlinkBootstrapClasses} ${
                    possibleSourcesHash.includes(hash) ? 'active' : ''
                  }`}
                >
                  {' '}
                  Sources{' '}
                </HashLink>
                <HashLink
                  to="#metrics"
                  data-toggle="tab"
                  data-target="#metrics"
                  className={`${hashlinkBootstrapClasses} ${
                    possibleMetricsHash.includes(hash) ? 'active' : ''
                  }`}
                >
                  {' '}
                  Metrics{' '}
                </HashLink>
                <HashLink
                  to="#attributes"
                  data-toggle="tab"
                  data-target="#attributes"
                  className={`${hashlinkBootstrapClasses} ${
                    possibleAttributesHash.includes(hash) ? 'active' : ''
                  }`}
                >
                  {' '}
                  Attributes{' '}
                </HashLink>
                <HashLink
                  to="#assoctypeont"
                  data-toggle="tab"
                  data-target="#assoctypeont"
                  className={`${hashlinkBootstrapClasses} ${hash === '#assoctypeont' ? 'active' : ''}`}
                >
                  DISGENET Association Type Ontology
                </HashLink>

                <h5 className="mt-3">How To Cite Us</h5>
                <HashLink
                  to="#cite_us"
                  data-toggle="tab"
                  data-target="#cite_us"
                  className={`${hashlinkBootstrapClasses} ${hash === '#cite_us' ? 'active' : ''}`}
                >
                  How to cite us
                </HashLink>
                <h5 className="mt-3">Version History</h5>
                <HashLink
                  to="#version_24.2"
                  data-toggle="tab"
                  data-target="#version_24.2"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_24.2' ? 'active' : ''}`}
                >
                  Version 24.2
                </HashLink>
                <HashLink
                  to="#version_24.1"
                  data-toggle="tab"
                  data-target="#version_24.1"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_24.1' ? 'active' : ''}`}
                >
                  Version 24.1
                </HashLink>
                <HashLink
                  to="#version_24"
                  data-toggle="tab"
                  data-target="#version_24"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_24' ? 'active' : ''}`}
                >
                  Version 24
                </HashLink>
                <HashLink
                  to="#version_23.1"
                  data-toggle="tab"
                  data-target="#version_23.1"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_23.1' ? 'active' : ''}`}
                >
                  Version 23.1
                </HashLink>
                <HashLink
                  to="#version_23"
                  data-toggle="tab"
                  data-target="#version_23"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_23' ? 'active' : ''}`}
                >
                  Version 23
                </HashLink>
                <HashLink
                  to="#version_22"
                  data-toggle="tab"
                  data-target="#version_22"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_22' ? 'active' : ''}`}
                >
                  Version 22
                </HashLink>
                <HashLink
                  to="#version_21"
                  data-toggle="tab"
                  data-target="#version_21"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_21' ? 'active' : ''}`}
                >
                  Version 21
                </HashLink>
                <HashLink
                  to="#version_20.1"
                  data-toggle="tab"
                  data-target="#version_20.1"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_20.1' ? 'active' : ''}`}
                >
                  Version 20.1
                </HashLink>
                <HashLink
                  to="#version_20"
                  data-toggle="tab"
                  data-target="#version_20"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_20' ? 'active' : ''}`}
                >
                  Version 20
                </HashLink>
                <HashLink
                  to="#version_19"
                  data-toggle="tab"
                  data-target="#version_19"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_19' ? 'active' : ''}`}
                >
                  Version 19
                </HashLink>
                <HashLink
                  to="#version_18"
                  data-toggle="tab"
                  data-target="#version_18"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_18' ? 'active' : ''}`}
                >
                  Version 18
                </HashLink>
                <HashLink
                  to="#version_17"
                  data-toggle="tab"
                  data-target="#version_17"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_17' ? 'active' : ''}`}
                >
                  Version 17
                </HashLink>
                <HashLink
                  to="#version_16"
                  data-toggle="tab"
                  data-target="#version_16"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_16' ? 'active' : ''}`}
                >
                  Version 16
                </HashLink>
                <HashLink
                  to="#version_15"
                  data-toggle="tab"
                  data-target="#version_15"
                  className={`${hashlinkBootstrapClasses} ${hash === '#version_15' ? 'active' : ''}`}
                >
                  Version 15
                </HashLink>
              </div>
            </div>

            <div className="col-md-9">
              <div>
                <div className="tab-content" id="dbInfoTabbedPane">
                  <div
                    className={`tab-pane ${hash === '' || hash === '#gdaStatistics' ? 'active show' : ''}`}
                    id="gdaStatistics"
                  >
                    <h2>Statistics</h2>
                    <p>
                      The current version of DISGENET (v24.2) contains 1,934,730 gene-disease associations
                      (GDAs), between 26,392 genes and 39,903 diseases and traits; 1,254,874 variant-disease
                      associations (VDAs), between 719,161 variants and 17,029 diseases and traits, and over
                      27,494,442 M disease-disease associations. DISGENET also contains more than 340,000
                      associations involving 12,000 diseases detected in animal models. This release provides
                      annotations for 4,032 chemicals associated with GDAs and VDAs. 1,529,620 publications
                      support the GDAs, and 180,533 publications support the VDAs. In total, DISGENET contains
                      a corpus of 1,539,056 publications that support the genotype-phenotype associations.
                    </p>
                    <h3 className="mt-5">Summary stats for latest releases:</h3>
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 20px 60px 10px' }}
                    >
                      <ReleasesTables />
                    </table>
                    <img
                      src="../../static/images/Release_GenoPheno.svg"
                      style={{
                        float: 'middle',
                      }}
                      alt="DISGENET statistics"
                    />

                    <h3 className="mt-5">Distribution of genes, diseases, and GDAs by source</h3>
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 20px 40px 10px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Source</th>
                          <th>Genes</th>
                          <th>Diseases</th>
                          <th>GDAs</th>
                          <th>Evidences</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <TableFromFile filePath="../../static/files/gdas_stats.tsv" />
                      </tbody>
                    </table>

                    <h3 className="mt-5">Distribution of variants, diseases, and VDAs by source</h3>
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 20px 40px 10px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Source</th>
                          <th>Variants</th>
                          <th>Diseases</th>
                          <th>VDAs</th>
                          <th>Evidences</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <TableFromFile filePath="../../static/files/vdas_stats.tsv" />
                      </tbody>
                    </table>
                    <h3 className="mt-5">DISGENET provides ample coverage of all disease areas...</h3>

                    <img
                      src="../../static/images/plot_msh.svg"
                      style={{ float: 'middle', margin: '20px 20px 20px 10px' }}
                      width="100%"
                      height="100%"
                      alt="DISGENET therapeutic areas"
                    />
                    <h3 className="mt-5">...and protein classes relevant for chemical R&D</h3>
                    <img
                      src="../../static/images/plot_dto.svg"
                      style={{ float: 'middle', margin: '20px 20px 20px 10px' }}
                      width="100%"
                      height="100%"
                      alt="DISGENET protein classes"
                    />
                  </div>

                  <div className={`tab-pane ${hash === '#gene_type' ? 'active show' : ''}`} id="gene_type">
                    <h2 className="mt-5">
                      DISGENET provides disease information for protein coding genes as well as non-coding
                      genes
                    </h2>
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 10px 0px 30px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Type of Gene</th>
                          <th>Number of genes</th>
                          <th>Number of GDAS</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <TableFromFile filePath="../../static/files/gene_type_stats.tsv" />
                      </tbody>
                    </table>
                  </div>

                  <div
                    className={`tab-pane ${hash === '#variant_type' ? 'active show' : ''}`}
                    id="variant_type"
                  >
                    <h2 className="mt-5">
                      Distribution of variants, and variant-disease associations according to the type of
                      variant
                    </h2>
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 10px 0px 30px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Type of Variant</th>
                          <th>Number of variants</th>
                          <th>Number of VDAS</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <TableFromFile filePath="../../static/files/variant_type_stats.tsv" />
                      </tbody>
                    </table>
                  </div>

                  <div className={`tab-pane ${hash === '#schema' ? 'active show' : ''}`} id="schema">
                    <h2>The DISGENET schema</h2>
                    <p>
                      DISGENET data is standardized using community-supported standards and ontologies. The
                      data can be filtered and selected by using different attributes, metrics and scores
                      available for the genes, variants, diseases and their associations.
                    </p>
                    <img
                      src="../../static/images/Release_DGNplusSchema_SVG.svg"
                      style={{ float: 'middle', margin: '20px 20px 20px 40px' }}
                      width="80%"
                      height="80%"
                      alt="DISGENET schema"
                    />
                    <div className="divider" />
                  </div>

                  {/* aqui */}
                  <div
                    className={`tab-pane ${possibleSourcesHash.includes(hash) ? 'active show' : ''}`}
                    id="sources"
                  >
                    <h2>Original Data Sources</h2>
                    <p>
                      DISGENET integrates information on human gene-disease associations (GDAs) and
                      variant-disease associations (VDAs) from various repositories specialized in different
                      disease categories that include Mendelian, complex, and environmental diseases.
                    </p>
                    <div id="section11">
                      <h3>Gene-Disease Associations</h3>
                      <p>Gene-disease data is organized according to the types of source databases:</p>
                      <ul>
                        <li className="bullet-item">
                          CURATED: GDAs provided by the expert curated resources such as{' '}
                          <a className="tt" target="blank" href="https://www.uniprot.org/">
                            UniProt/SwissProt (UniProt)
                          </a>
                          ,
                          <a className="tt" target="blank" href="https://www.ncbi.nlm.nih.gov/clinvar/">
                            {' '}
                            ClinVar
                          </a>
                          ,
                          <a className="tt" target="blank" href="http://psygenet.org/web/PsyGeNET/menu">
                            {' '}
                            PsyGeNET
                          </a>
                          ,
                          <a
                            className="tt"
                            target="blank"
                            href="https://www.orpha.net/consor/cgi-bin/index.php"
                          >
                            {' '}
                            Orphanet
                          </a>
                          , and{' '}
                          <a className="tt" target="blank" href="https://www.clinicalgenome.org/">
                            {' '}
                            The Clinical Genome Resource
                          </a>{' '}
                          (ClinGen), and{' '}
                          <a className="tt" target="blank" href="https://www.informatics.jax.org/">
                            {' '}
                            MGD
                          </a>{' '}
                          (MGD human), and{' '}
                          <a className="tt" target="blank" href="https://rgd.mcw.edu/">
                            {' '}
                            RGD
                          </a>{' '}
                          (RGD human).
                        </li>
                        <li className="bullet-item">
                          MODELS: These data refer to GDAs identified in several models (mouse, rat,
                          zebrafish) using text mining approaches and obtained from the database{' '}
                          <a className="tt" target="blank" href="https://www.informatics.jax.org/">
                            {' '}
                            MGD
                          </a>{' '}
                          (MGD mouse) and{' '}
                          <a className="tt" target="blank" href="https://rgd.mcw.edu/">
                            {' '}
                            RGD
                          </a>{' '}
                          (RGD rat).
                        </li>
                        <li className="bullet-item">
                          INFERRED: These data refer to GDAs inferred from{' '}
                          <a className="tt" target="blank" href="https://hpo.jax.org/app/">
                            Human Phenotype Ontology
                          </a>{' '}
                          (HPO) and the VDAs reported by the GWAS and Phewas catalog. In the case of HPO, GDAs
                          are inferred from phenotype-disease and gene-phenotype associations via inference
                          through the common phenotypes. In the case of VDAs, a GDA is inferred for each VDA,
                          for those variants that map to gene coding sequences.
                        </li>
                        <li className="bullet-item">
                          CLINICAL TRIALS: GDAs extracted using text mining from clinical trials records
                          available at{' '}
                          <a className="tt" target="blank" href="https://clinicaltrials.gov/">
                            clinicaltrials.gov
                          </a>
                          . Disease annotations are obtained from{' '}
                          <a className="tt" target="blank" href="https://aact.ctti-clinicaltrials.org/">
                            aact.ctti-clinicaltrials.org
                          </a>
                          .
                        </li>
                        <li className="bullet-item">
                          TEXT MINING MODELS: GDAs identified by text mining from the scientific literature in
                          studies using model systems (animal models).
                        </li>
                        <li className="bullet-item">
                          TEXT MINING HUMAN: GDAs identified by text mining from the scientific literature in
                          studies performed on human subjects.
                        </li>
                        <li className="bullet-item">ALL: GDAs from all previous sources.</li>
                      </ul>
                    </div>
                    <div id="section12">
                      <h3>Variant-Disease Associations</h3>
                      <p>
                        The variant-disease information in DISGENET is organized according to two types of
                        source databases:
                      </p>
                      <ul>
                        <li className="bullet-item">
                          CURATED: VDAs from
                          <a className="tt" target="blank" href="https://www.uniprot.org/">
                            {' '}
                            UniProt/SwissProt
                          </a>
                          ,
                          <a className="tt" target="blank" href="https://www.ebi.ac.uk/gwas/">
                            {' '}
                            ClinVar
                          </a>
                          ,
                          <a className="tt" target="blank" href="https://phewascatalog.org/">
                            {' '}
                            Phewas Catalog
                          </a>
                          , and the
                          <a className="tt" target="blank" href="https://www.ebi.ac.uk/gwas/">
                            {' '}
                            NHGRI-EBI GWAS Catalog
                          </a>
                          .
                        </li>
                        <li className="bullet-item">
                          TEXT MINING HUMAN: VDAs identified by text mining from the scientific literature.
                        </li>
                        <li className="bullet-item">
                          ALL: VDAs from previous sources and VDAs obtained via text mining approaches.
                        </li>
                      </ul>
                    </div>
                    <div id="section13">
                      <h3>Disease-Disease Associations</h3>
                      <p>
                        The disease-disease associations (DDA) in DISGENET have been obtained by computing the
                        number of shared genes, and shared variants between pairs of diseases, by source. The
                        DDAs can be explored from the Search panel by searching by disease (one or multiple
                        diseases).
                      </p>
                      <p>
                        Disease-Disease associations are obtained as described below from all the sources
                        available for GDAs and VDAs.
                      </p>
                      <p className="top2">
                        For each disease pair, we computed a Jaccard Index (JI), to assess the fraction of
                        shared genes (or variants) among the diseases according to:
                      </p>

                      <div className="row">
                        <div className="col-sm-5">{jaccardGda()}</div>
                        <div className="col-sm-offset-1 col-sm-4">
                          <dt>where: </dt>Through the
                          <p>
                            G<sub>1</sub> is the set of genes associated to Disease 1
                          </p>
                          <p>
                            G<sub>2</sub> is the set of genes associated to Disease 2
                          </p>
                        </div>
                      </div>
                      <div className="row top5">
                        <div className="col-sm-5">{jaccardVda()}</div>
                        <div className="col-sm-offset-1 col-sm-4">
                          <dt>where: </dt>
                          <p>
                            V<sub>1</sub> is the set of variants associated to Disease 1
                          </p>
                          <p>
                            V<sub>2</sub> is the set of variants associated to Disease 2
                          </p>
                        </div>
                      </div>
                      <p className="mt-3">
                        For each disease-disease association, we perform two Fisher's exact tests, one based
                        on the shared genes, and one on the shared variants, and we filter the associations to
                        keep only those with p values of the Fisher test for the genes or for the variants
                        equal or smaller than 10
                        <sup>-6</sup>
                      </p>
                    </div>
                  </div>

                  <div
                    className={`tab-pane ${possibleMetricsHash.includes(hash) ? 'active show' : ''}`}
                    id="metrics"
                  >
                    <h2>DISGENET Metrics</h2>
                    <p>
                      We have developed two scores to rank the gene-disease, and the variant-disease
                      associations according to their level of evidence. These scores range from 0 to 1, and
                      take into account the number and type of sources (level of curation, model organisms),
                      and the number of publications supporting the association.
                    </p>

                    <div>
                      <div id="gdaScore">
                        <h3>GDA Score</h3>
                        <p>The DISGENET Score (S) for GDAs is computed according to: </p>
                        <span className="mx-auto"> {gdaScore()}</span>
                        <dt>where: </dt>
                        <span>{gdaScore1()}</span>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N
                            <sub>
                              sources<sub>i</sub>
                            </sub>{' '}
                            is the number of CURATED sources supporting a GDA
                          </p>
                          <p>i &#8712; CLINGEN, CLINVAR, PSYGENET, ORPHANET, UNIPROT, MGD HUMAN, RGD HUMAN</p>
                        </ul>
                        <span>{gdaScore2()}</span>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            Nsources<sub>j</sub> is the number of MODEL sources supporting a GDA.
                          </p>
                        </ul>
                        <ul>
                          <p>j &#8712; RGD_RAT, MGD_MOUSE, TEXTMINING_MODELS.</p>
                        </ul>
                        {gdaScore3()}
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N
                            <sub>
                              sources<sub>k</sub>
                              is the number of INFERRED sources supporting a GDA
                            </sub>{' '}
                          </p>
                          <p>k &#8712; HPO, GWASCAT, PHEWASCAT</p>
                        </ul>
                        <span>{gdaScore4()}</span>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N<sub>pubs</sub> is the number of publications supporting a GDA extracted by text
                            mining.
                          </p>
                        </ul>
                        {/* new T */}
                        <span>{gdaScore5()}</span>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N<sub>c</sub> is the number of Clinical Trials supporting the GDA.
                          </p>
                        </ul>
                      </div>
                      <div className="divider" />
                      <div id="vdaScore">
                        <h3>VDA Score</h3>
                        <p>TheDISGENET Score (S) for VDAs is computed according to:</p>
                        <span>{vdaScore()}</span>
                        <dt>where: </dt>
                        <span>{vdaScore1()}</span>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N
                            <sub>
                              sources<sub>i</sub>
                            </sub>{' '}
                            is the number of CURATED sources supporting a VDA
                          </p>
                          <p>i &#8712; UNIPROT,CLINVAR, GWASCAT, PHEWASCAT</p>
                        </ul>
                        <span>{vdaScore2()}</span>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N<sub>pubs</sub> is the number of publications supporting a VDA extracted by text
                            mining.
                          </p>
                        </ul>
                      </div>
                      <div className="divider" />
                      <div id="specificity">
                        <h3>Disease Specificity Index</h3>
                        There are genes (or variants) that are associated with multiple diseases (e.g.TNF)
                        while others are associated with a small set of diseases or even to a single
                        disease.The Disease Specificity Index (DSI) is a measure of this property of the genes
                        (and variants).It reflects if a gene (or variant) is associated to several or fewer
                        diseases.It is computed according to:
                        <span>{DiseaseSpecificityIndex()}</span>
                        <dt>where: </dt>
                        <ul>
                          - N<sub>d</sub> is the number of diseases associated to the gene/variant - N
                          <sub>T</sub> is the total number of diseases in DISGENET
                        </ul>
                        <p>
                          The DSI ranges from 0.23 to 1. Example: TNF, associated with more than 3,500
                          diseases, has a DSI of 0.23, while MOB3A is associated with one disease, with a DSI
                          of 1.
                        </p>
                        <p>
                          If the DSI is empty, it implies that the gene/variant is associated only to
                          phenotypes.
                        </p>
                      </div>
                      <div className="divider" />
                      <div id="pleiotropy">
                        <h3>Disease Pleiotropy Index</h3>
                        The rationale is similar than for the DSI, but we consider if the multiple diseases
                        associated to the gene (or variant) are similar among them (belong to the same MeSH
                        disease class , e.g. Cardiovascular Diseases) or are completely different diseases and
                        belong to different disease classes.The Disease Pleiotropy Index (DPI) is computed
                        according to:
                        <span>{DiseasePleiotropyIndex()}</span>
                        <dt>where: </dt>
                        <ul>
                          - N<sub>dc</sub> is the number of the different MeSH disease classes of the diseases
                          associated to the gene/variant - N<sub>TC</sub> is the total number of MeSH diseases
                          classes in DISGENET (29)
                        </ul>
                        <p>
                          The DPI ranges from 0 to 1. Example: gene KCNT1 is associated to 39 diseases, 4
                          disease groups, and 18 phenotypes. 29 out of the 39 diseases have a MeSH disease
                          class. The 29 diseases are associated to 5 different MeSH classes. The DPI index for
                          KCNT1 = 5/29 ~0.172. Nevertheless, gene APOE, associated to more than 700 diseases
                          of 27 disease classes has a DPI of 0.931.
                        </p>
                        <p>
                          If the gene/variant has no DPI value, it implies that the gene/variant is associated
                          only to phenotypes, or that the associated diseases do not map to any MeSH classes.
                        </p>
                      </div>
                      <div className="divider" />

                      <div id="evindex">
                        <h3>Evidence Index</h3>
                        <p>
                          The "Evidence index" (EI) indicates the existence of contradictory results in
                          publications supporting the gene/variant-disease associations. This index is
                          computed for associations supported by text mining, and by PsyGeNET, by identifying
                          the publications reporting a negative finding on a particular VDA or GDA. Note that
                          only in the case of PsyGeNET, the information used to compute the EI has been
                          validated by experts.
                        </p>
                        <p>The EI is computed as follows: </p>
                        <span>{evidenceIndex()}</span>
                        <p>
                          EI = 1 indicates that all the publications support the GDA or the VDA, while{' '}
                          {'EI < 1'} indicates that there are publications that assert that there is no
                          association between the gene/variants and the disease. If the gene/variant has no EI
                          value, it indicates that the index has not been computed for this association.
                        </p>
                        <dt>where: </dt>
                        <ul>
                          <p>
                            N
                            <sub>
                              pubs<sub>+</sub>
                            </sub>{' '}
                            is the number of publication supporting an association
                          </p>
                          <p>
                            N
                            <sub>
                              pubs<sub>total</sub>
                            </sub>{' '}
                            is the total number of publications.
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane ${possibleAttributesHash.includes(hash) ? 'active show' : ''}`}
                    id="attributes"
                  >
                    <h2>Data Attributes</h2>
                    <p>
                      In order to ease the interpretation and analysis of gene-disease, variant-disease
                      associations, and disease-disease associations we provide the following information for
                      the data.
                    </p>
                    <div id="section41">
                      <h3>Genes</h3>
                      <p>
                        <strong>Genes in DISGENET are annotated with: </strong>
                      </p>
                      <ul>
                        <li className="bullet-item">
                          The official gene symbol, from the{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="http://www.ncbi.nlm.nih.gov/gene/"
                          >
                            NCBI
                          </a>
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="http://www.ncbi.nlm.nih.gov/gene/"
                          >
                            NCBI
                          </a>{' '}
                          Official Full Name
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <a className="tt" target="_blank" rel="noreferrer" href="https://www.ensembl.org/">
                            {' '}
                            Ensembl
                          </a>{' '}
                          Gene identifier
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="http://www.uniprot.org/uniprot/"
                          >
                            Uniprot{' '}
                          </a>{' '}
                          accession
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#specificity"
                            data-target="#metrics"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            Disease Specificity Index{' '}
                          </HashLink>
                          (DSI)
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#pleiotropy"
                            data-target="#metrics"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            Disease Pleiotropy Index{' '}
                          </HashLink>
                          (DPI)
                        </li>
                        <li className="bullet-item">
                          The pLI, defined as the probability of being loss-of-function intolerant, is a gene
                          constraint metric provided by the{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="https://gnomad.broadinstitute.org/"
                          >
                            GNOMAD
                          </a>{' '}
                          consortium. A gene constraint metric aims at measuring how the naturally occurring
                          LoF (loss of function) variation has been depleted from a gene by natural selection
                          (in other words, how intolerant is a gene to LoF variation). LoF intolerant genes
                          have a high pLI value (&gt;=0.9), while LoF tolerant genes have low pLI values
                          (&lt;=0.1). The LoF variants considered are nonsense and essential splice site
                          variants.
                        </li>
                        <li className="bullet-item">
                          The protein class is obtained from the top level category from the{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="http://drugtargetontology.org/"
                          >
                            Chemical Target Ontology
                          </a>
                          .
                        </li>
                        <li className="bullet-item">
                          The pathways are obtained from top-level pathways from{' '}
                          <a className="tt" target="_blank" rel="noreferrer" href="https://reactome.org/">
                            Reactome
                          </a>
                          .
                        </li>
                      </ul>
                    </div>
                    <div id="section42">
                      <h3>Variants</h3>
                      <strong>
                        <p>Variants in DISGENET are annotated with:</p>
                      </strong>
                      <ul>
                        <li className="bullet-item">
                          The position in the chromosome according to <b>dbSNP</b>, the{' '}
                          <a className="tt" target="blank" href="http://www.ncbi.nlm.nih.gov/projects/SNP/">
                            NCBI Short Genetic Variations database{' '}
                          </a>
                        </li>
                        <li className="bullet-item">The reference and alternative alleles</li>
                        <li className="bullet-item">
                          The class of the variant: SNP, deletion, insertion, indel, somatic SNV,
                          substitution, sequence alteration, and tandem repeat{' '}
                        </li>
                        <li className="bullet-item">
                          The allelic frequency in genomes and exomes according to <b>GNOMAD</b>, the &nbsp;
                          <a className="tt" target="blank" href="http://gnomad.broadinstitute.org/">
                            Genome Aggregation Database
                          </a>
                          . The data spans 125,748 exomes and 71,702 genomes from unrelated individuals
                          sequenced as part of various disease-specific and population genetic studies (
                          <a className="tt" target="blank" href="http://gnomad.broadinstitute.org/downloads">
                            release 2.1.1
                          </a>{' '}
                          for exomes and 3.0 for genomes)
                        </li>
                        <li className="bullet-item">
                          The most severe consequence type according to the{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="http://www.ensembl.org/info/genome/variation/predicted_data.html"
                          >
                            Variant Effect Predictor
                          </a>
                        </li>
                        <li className="bullet-item">
                          The gene corresponding to the consequence type assigned by VEP, or the one assigned
                          by dbSNP database
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#specificity"
                            data-target="#metrics"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            Disease Specificity Index
                          </HashLink>{' '}
                          (DSI)
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#pleiotropy"
                            data-target="#metrics"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            Disease Pleiotropy Index{' '}
                          </HashLink>{' '}
                          (DPI)
                        </li>
                        <li className="bullet-item">
                          The risk allele is the allele associated with the phenotype as provided by the GWAS
                          catalogue or ClinVar.
                        </li>
                        <li className="bullet-item">
                          <strong> Pathogenicity scores SIFT and PolyPhen-2:</strong> For human variants that
                          are predicted to result in an amino acid substitution, we provide the pathogenicity
                          predictions from SIFT and PolyPhen-2 obtained from{' '}
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.ensembl.org/info/genome/variation/prediction/protein_function.html"
                          >
                            VEP
                          </a>
                          . For each variant, we provide the max value of Polyphen and the minimum value of
                          SIFT.
                        </li>
                        <li className="bullet-item special">
                          <a rel="noreferrer" target="_blank" href="http://sift.bii.a-star.edu.sg/">
                            SIFT
                          </a>{' '}
                          predicts whether an amino acid substitution is likely to affect protein function
                          based on sequence homology and the physicochemical similarity between the alternate
                          amino acids. The score is the normalized probability that the amino acid change is
                          tolerated; scores near zero are more likely to be deleterious. A qualitative
                          prediction can be derived from this score such that substitutions with a score {'<'}{' '}
                          0.05 are called {'deleterious'} and all others are called
                          {'tolerated'}.
                        </li>

                        <table
                          className="table panther-table"
                          style={{
                            margin: '10px 50px 10px 50px',
                          }}
                        >
                          <thead className="text-center">
                            <td>
                              <strong>SIFT value</strong>
                            </td>
                            <td>
                              <strong>Qualitative prediction</strong>
                            </td>
                          </thead>
                          <tbody className="text-center">
                            <td>smaller than 0.05</td>
                            <td>{'Deleterious'}</td>
                          </tbody>
                        </table>

                        <li className="bullet-item special">
                          <a rel="noreferrer" target="_blank" href="http://genetics.bwh.harvard.edu/pph2/">
                            PolyPhen-2
                          </a>{' '}
                          predicts the effect of an amino acid substitution on the structure and function of a
                          protein using sequence homology, Pfam annotations, 3D structures from PDB where
                          available, and several other databases and tools (including DSSP, ncoils etc.). The
                          PolyPhen score represents the probability that a substitution is damaging, so values
                          nearer one are more confidently predicted to be deleterious (note that this is the
                          opposite of SIFT). A qualitative classification can be derived from the PolyPhen
                          score as follows:
                        </li>

                        <table
                          className="table panther-table"
                          style={{
                            margin: '10px 50px 10px 50px',
                          }}
                        >
                          <thead className="text-center">
                            <td>
                              <strong>Polyphen value</strong>
                            </td>
                            <td>
                              <strong>Qualitative prediction</strong>
                            </td>
                          </thead>
                          <tbody className="text-center">
                            <td>greater than 0.908</td>
                            <td>"Probably Damaging"</td>
                          </tbody>
                          <tbody className="text-center">
                            <td style={{ width: '500px' }}>
                              greater than 0.446 and less than or equal to 0.908
                            </td>
                            <td>"Possibly Damaging"</td>
                          </tbody>
                          <tbody className="text-center">
                            <td>less than or equal to 0.446</td>
                            <td>"Benign"</td>
                          </tbody>
                          <tbody className="text-center">
                            <td>Unknown</td>
                            <td>Unknown</td>
                          </tbody>
                        </table>
                      </ul>
                    </div>
                    <div id="section43">
                      <h3>Diseases</h3>
                      <p>
                        The vocabulary used for diseases in DISGENET is the
                        <a
                          href="https://www.nlm.nih.gov/research/umls/index.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          UMLS<sup>®</sup> Metathesaurus<sup>®</sup>.
                        </a>{' '}
                        Each disease has a Concept Unique Identifier (CUI) from the Unified Medical Language
                        System<sup>®</sup> (UMLS) Metathesaurus<sup>®</sup> (version UMLS 2019AB).
                      </p>
                      <div>
                        <p>
                          <strong>Diseases in DISGENET are annotated with: </strong>
                        </p>
                        <ul>
                          <li className="bullet-item">
                            The disease name, provided by the{' '}
                            <a
                              className="tt"
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.nlm.nih.gov/research/umls/"
                            >
                              UMLS<sup>®</sup> Metathesaurus<sup>®</sup>
                            </a>
                          </li>
                          <li className="bullet-item">
                            The{' '}
                            <a
                              className="tt"
                              target="_blank"
                              rel="noreferrer"
                              href="http://www.nlm.nih.gov/research/umls/META3_current_semantic_types.html"
                            >
                              UMLS<sup>®</sup> semantic types{' '}
                            </a>
                            the{' '}
                            <a
                              className="tt"
                              target="_blank"
                              rel="noreferrer"
                              href="https://meshb.nlm.nih.gov/treeView"
                            >
                              MeSH
                            </a>{' '}
                            class: we classify the diseases according the MeSH hierarchy using the upper level
                            concepts of the MeSH tree branch C (Diseases) plus three concepts of the F branch
                            (Psychiatry and Psychology: "Behavior and Behavior Mechanisms", "Psychological
                            Phenomena and Processes", and "Mental Disorders")
                          </li>
                          <li className="bullet-item">
                            The top level concepts from the{' '}
                            <a
                              className="tt"
                              target="_blank"
                              rel="noreferrer"
                              href="http://disease-ontology.org/"
                            >
                              Human Disease Ontology
                            </a>
                          </li>
                          <li className="bullet-item">
                            The top level concepts from the{' '}
                            <a
                              className="tt"
                              target="_blank"
                              rel="noreferrer"
                              href="https://hpo.jax.org/app/"
                            >
                              Human Phenotype Ontology
                            </a>
                          </li>
                          <li className="bullet-item">
                            TheDISGENET disease type: <b>disease</b>, <b>phenotype</b> and <b>group</b>.
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>
                            We consider a <b>disease</b> entries mapping to the following UMLS<sup>®</sup>{' '}
                            semantic types:
                          </strong>
                        </p>
                        <ul>
                          <li className="bullet-item">Disease or Syndrome</li>
                          <li className="bullet-item">Neoplastic Process</li>
                          <li className="bullet-item">Acquired Abnormality</li>
                          <li className="bullet-item">Anatomical Abnormality</li>
                          <li className="bullet-item">Congenital Abnormality</li>
                          <li className="bullet-item">Mental or Behavioral Dysfunction</li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>
                            We consider a <b>phenotype</b> entries mapping to the following UMLS<sup>®</sup>{' '}
                            semantic types:
                          </strong>
                        </p>
                        <ul>
                          <li className="bullet-item">Pathologic Function</li>
                          <li className="bullet-item">Sign or Symptom</li>
                          <li className="bullet-item">Finding</li>
                          <li className="bullet-item">Laboratory or Test Result</li>
                          <li className="bullet-item">Individual Behavior</li>
                          <li className="bullet-item">Clinical Attribute</li>
                          <li className="bullet-item">Organism Attribute</li>
                          <li className="bullet-item">Organism Function</li>
                          <li className="bullet-item">Organ or Tissue Function</li>
                          <li className="bullet-item">Cell or Molecular Dysfunction</li>
                        </ul>
                      </div>
                      <p>
                        These classifications were manually checked. In addition, disease entries referring to
                        disease groups such as "Cardiovascular Diseases", "Autoimmune Diseases",
                        "Neurodegenerative Diseases, and "Lung Neoplasms" were classified as{' '}
                        <b>disease group</b>.
                      </p>
                      <div>
                        Additionally, we have removed terms considered as diseases by other sources, but are
                        not strictly diseases, such as terms belonging to the following UMLS
                        <sup>®</sup> semantic types:
                        <ul>
                          <li className="bullet-item">Gene or Genome</li>
                          <li className="bullet-item">Genetic Function</li>
                          <li className="bullet-item">Immunologic Factor</li>
                          <li className="bullet-item">Injury or Poisoning</li>
                        </ul>
                      </div>
                    </div>
                    <div id="section44">
                      <h3>Chemicals</h3>
                      <p>
                        The vocabulary used for chemicals in DISGENET is the
                        <a
                          href="https://www.nlm.nih.gov/research/umls/index.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          UMLS<sup>®</sup> Metathesaurus<sup>®</sup>.
                        </a>{' '}
                        Each chemical has a Concept Unique Identifier (CUI) from the Unified Medical Language
                        System<sup>®</sup> (UMLS) Metathesaurus<sup>®</sup> (version UMLS 2019AB).
                      </p>

                      <p>
                        <strong>Chemicals in DISGENET are annotated with: </strong>
                      </p>

                      <ul>
                        <li className="bullet-item">
                          The chemical name, provided by the{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.nlm.nih.gov/research/umls/"
                          >
                            UMLS<sup>®</sup> Metathesaurus<sup>®</sup>
                          </a>
                        </li>
                        <li className="bullet-item">
                          The chemical CUI (Concept Unique Identifier) from the{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.nlm.nih.gov/research/umls/"
                          >
                            UMLS<sup>®</sup> Metathesaurus<sup>®</sup>
                          </a>
                        </li>
                        <li className="bullet-item">
                          The number of publications with chemical mentions (N. PMIDS)
                        </li>
                        <li className="bullet-item">
                          The number of publications that support GDAs with chemical mentions (N. PMIDs GDA)
                        </li>
                        <li className="bullet-item">
                          The number of publications that support VDAs with chemical mentions (N. PMIDs VDA)
                        </li>
                        <li className="bullet-item">
                          “Chemical Effect”: classification of the effect of a chemical on the disease,
                          provided processing expert-curated resources that provide adverse effect information
                          and indications. By using the “Chemical Effect” attribute, you can select chemicals
                          that are used for the treatment of a disease (therapeutic) or lead to a disease
                          (toxicity). This attribute is available in the GDA and VDA Evidence tables with
                          chemical annotations.
                        </li>
                      </ul>
                    </div>
                    <div id="section45">
                      <h3>Gene-Disease Associations</h3>
                      <ul>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#gdaScore"
                            data-target="#metrics"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            DISGENET score
                          </HashLink>
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#assoctypeont"
                            data-target="#assoctypeont"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            DISGENET Gene-Disease Association Type
                          </HashLink>
                        </li>
                        <li className="bullet-item">
                          The Evidence Level The Evidence Level (EL) is a metric developed by ClinGen that
                          measures the strength of evidence of a gene-disease relationship that correlates to
                          a qualitative classification:{' '}
                          <i>"Definitive", "Strong", "Moderate", "Limited", "Conflicting Evidence"</i>, or{' '}
                          <i>"No Reported Evidence" </i>(
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.ncbi.nlm.nih.gov/pubmed/28552198"
                          >
                            Strande <i>et al.</i>, 2017{' '}
                          </a>
                          ). GDAs that have been reported by ClinGen will have their corresponding Evidence
                          Level. Furthermore, we have adapted a similar metric reported by Genomics England
                          PanelApp to correspond to the same categories from ClinGen: GDAs marked by Genomics
                          England PanelApp as
                          <i>High Evidence</i> are labeled as <i>strong</i> in DISGENET. Those labeled as{' '}
                          <i>Moderate Evidence</i> are labeled as <i>moderate</i> and <i>Low Evidence</i>{' '}
                          associations are labeled as <i>limited</i>. We have labeled GDAs with no evidence
                          level as "No reported evidence"
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink
                            to="#evindex"
                            data-target="#metrics"
                            data-toggle="tab"
                            className="js-fix-scroll"
                          >
                            Evidence Index
                          </HashLink>
                        </li>
                        <li className="bullet-item">
                          The year initial: first time that the association was reported
                        </li>
                        <li className="bullet-item">
                          The year final: last time that the association was reported
                        </li>
                        <li className="bullet-item">
                          The publication(s) that reports the gene-disease association, with the{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="http://www.ncbi.nlm.nih.gov/pubmed/"
                          >
                            {' '}
                            Pubmed Identifier
                          </a>
                        </li>
                        <li className="bullet-item">
                          A representative sentence from the publication describing the association between
                          the gene and the disease (if a representative sentence is not found, we provide the
                          title of the paper)
                        </li>
                        <li className="bullet-item">
                          The original source reporting the Gene-Disease Association.
                        </li>
                      </ul>
                    </div>
                    <div id="section46">
                      <h3>Variant-Disease Associations</h3>
                      <ul>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink to="#vdaScore" data-target="#metrics" data-toggle="tab">
                            DISGENET score
                          </HashLink>
                        </li>
                        <li className="bullet-item">
                          The{' '}
                          <HashLink to="#evindex" data-target="#metrics" data-toggle="tab">
                            Evidence Index
                          </HashLink>
                        </li>
                        <li className="bullet-item">
                          The publication(s) that reports the variant-disease association, with the &nbsp;
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="http://www.ncbi.nlm.nih.gov/pubmed/"
                          >
                            Pubmed Identifier
                          </a>{' '}
                        </li>
                        <li className="bullet-item">
                          The year initial: first time that the association was reported
                        </li>
                        <li className="bullet-item">
                          The year final: last time that the association was reported
                        </li>
                        <li className="bullet-item">
                          A representative sentence from the publication describing the association between
                          the variant and the disease (if a representative sentence is not found, we provide
                          the title of the paper){' '}
                        </li>
                        <li className="bullet-item">
                          The original source reporting the Variant-Disease Association.
                        </li>
                        <li className="bullet-item">
                          OR or BETA: reported odds ratio or beta-coefficient associated with strongest SNP
                          risk allele. Note that if an OR {'<'} 1 is reported this is inverted, along with the
                          reported allele, so that all ORs are {'>'} 1{' '}
                        </li>
                        <li className="bullet-item">
                          p-value: reported p-value for strongest SNP risk allele
                        </li>
                        <li className="bullet-item">
                          GOF/LOF: indicates that the variant is gain of function (GoF) or loss of function
                          (LoF) mutation associated with the phenotype
                        </li>
                      </ul>
                    </div>
                    <div id="section47">
                      <h3>Disease-Disease Associations</h3>
                      <ul>
                        <li className="bullet-item">Jaccard Index based on shared genes</li>
                        <li className="bullet-item">
                          -log(p-value JIg): we provide the minus decimal logarithm of the p-value of JIg
                        </li>
                        <li className="bullet-item">Jaccard Index based on shared variants</li>
                        <li className="bullet-item">
                          -log(p-value JIv): we provide the minus decimal logarithm of the p-value of JIv
                        </li>
                        <li className="bullet-item">
                          p-value: the p-value of the JI (for genes or variants) was obtained by a Fisher
                          test. Only those DDAs with p-value equal to or smaller than 10<sup>-6</sup> are
                          included in the dataset (for the Jaccard index of the genes or the variants)
                        </li>
                        <li className="bullet-item">
                          The Sokal-Sneath semantic similarity distance{' '}
                          <a
                            className="tt"
                            target="_blank"
                            rel="noreferrer"
                            href="https://pubmed.ncbi.nlm.nih.gov/21463704/"
                          >
                            (Sánchez et al., 2011)
                          </a>
                          &nbsp; computed on the taxonomic relations (only is-a type) provided by the Unified
                          Medical Language System Metathesaurus®. These types of relations can also be
                          retrieved using the DDA Relation “is_similar_to” in the disgenet2r R package{' '}
                        </li>
                        <li className="bullet-item">
                          DDA Relation: semantic relations between diseases obtained from the Unified Medical
                          Language System Metathesaurus®. The types of relations available are:
                          has_manifestation, has_associated_morphology, manifestation_of,
                          associated_morphology_of, is_finding_of_disease, due_to,
                          has_definitional_manifestation, has_associated_finding,
                          definitional_manifestation_of, disease_has_finding, cause_of, associated_finding_of,
                          is_similar_to.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className={`tab-pane ${hash === '#assoctypeont' ? 'active show' : ''}`}
                    id="assoctypeont"
                  >
                    <h2>The DISGENET Association Type Ontology</h2>
                    <p>
                      For a seamless integration of gene-disease association data, we developed the DISGENET
                      association type ontology. All association types as found in the original source
                      databases are formally structured from a parent{' '}
                      <font className="myfont"> GeneDiseaseAssociation </font> class if there is a
                      relationship between the gene/protein and the disease, and represented as ontological
                      classes.
                    </p>

                    <h3>DISGENET ontology for the GDAs</h3>

                    <img
                      src="../../static/images/disgenet-ontology_gdas.svg"
                      style={{ float: 'middle', margin: '20px 20px 20px 60px' }}
                      width="100%"
                      height="100%"
                      alt="DISGENET association types"
                    />
                    <p>
                      <strong>The description of each association type in our ontology is:</strong>
                    </p>
                    <div className="hangingindent">
                      <ul>
                        <li>
                          <b>Causal or Contributing</b>: This relationship indicates that a genotype (gene or
                          genetic variation) has some causal or contributing role influencing the condition (a
                          phenotype or disease)
                        </li>
                        <li>
                          <b>Biomarker</b>: This relationship indicates that the gene is a biomarker for the
                          disease
                        </li>
                        <li>
                          <b>Cell Marker</b>: This relationship indicates that a biomarker measured as cell
                          surface antigen is associated with the disease phenotype.
                        </li>
                        <li>
                          <b>Altered Expression</b>: This relationship indicates that an altered expression of
                          the gene is associated with the disease phenotype.
                        </li>
                        <li>
                          <b>Post-translational Modification: </b>: This relationship indicates that
                          alterations in the function of the protein by means of post-translational
                          modifications (methylation or phosphorylation of the protein) are associated with
                          the disease phenotype.
                        </li>
                        <li>
                          <b>Phosphorylation</b>: This relationship indicates that changes in levels of
                          protein phosphorylation are associated with the disease phenotype.
                        </li>
                        <li>
                          <b>Epigenomic Alterations</b>: This relationship indicates that changes in DNA
                          methylation patterns are associated with the disease phenotype.
                        </li>
                        <li>
                          <b>Genomic Alterations</b>: This relationship indicates that a genomic alteration is
                          linked to the gene associated with the disease phenotype.
                        </li>
                        <li>
                          <b>Genetic Variation</b>: This relationship indicates that a sequence variation (a
                          mutation, an SNP) is associated with the disease phenotype, but there is still no
                          evidence to say that the variation causes the disease.
                        </li>
                        <li>
                          <b>Causal Mutation</b>: This relationship indicates that there are allelic variants
                          or mutations known to cause the disease.
                        </li>
                        <li>
                          <b>Germline Causal Mutation</b>: This relationship indicates that there are germline
                          allelic variants or mutations known to cause the disease, and they may be passed on
                          to offspring.
                        </li>
                        <li>
                          <b>Somatic Causal Mutation</b>: This relationship indicates that there are somatic
                          allelic variants or mutations known to cause the disease, but they may not be passed
                          on to offspring.
                        </li>
                        <li>
                          <b>Chromosomal Rearrangement</b>: This relationship indicates that a gene is
                          included in a chromosomal rearrangement associated with a particular manifestation
                          of the disease.
                        </li>
                        <li>
                          <b>Fusion Gene</b>: This relationship indicates that the fusion between two
                          different genes (between promoter and/or other coding DNA regions) is associated
                          with the disease.
                        </li>
                        <li>
                          <b>Susceptibility Mutation</b>: This relationship indicates that a gene mutation in
                          a germ cell predisposes to the development of a disorder, and that is necessary but
                          not sufficient for the manifestation of the disease.
                        </li>
                        <li>
                          <b>Modifying Mutation</b>: This relationship indicates that a gene mutation is known
                          to modify the clinical presentation of the disease.
                        </li>
                        <li>
                          <b>Germline Modifying Mutation</b>: This relationship indicates that a germline gene
                          mutation modifies the clinical presentation of the disease, and it may be passed on
                          to offspring.
                        </li>
                        <li>
                          <b>Somatic Modifying Mutation</b>: This relationship indicates that a somatic gene
                          mutation modifies the clinical presentation of the disease, but it may not be passed
                          on to offspring.
                        </li>
                        <li>
                          <b>Protective</b>: This relationship indicates that a variant decreases the risk of
                          a disorder, including infections.
                        </li>
                        <li>
                          <b>Drug Response</b>: This relationship indicates that a variant affects the
                          response to a chemical.
                        </li>
                        <li>
                          <b>Variant of Uncertain significance (VUS)</b>: This relationship indicates that the
                          variant is of unknown clinical significance.
                        </li>
                      </ul>
                    </div>

                    <h3>DISGENET ontology for the VDAs</h3>
                    <img
                      src="../../static/images/disgenetplus_ontology_vdas_v31082023.svg"
                      style={{ float: 'middle', margin: '20px 20px 20px 60px' }}
                      width="100%"
                      height="100%"
                      alt="DISGENET association types"
                    />
                    <p>
                      {' '}
                      Additionally, for the VDAs, we have created in DISGENET two categories (Gain of
                      Function, and Loss of Function) that modify the terms in the ontology that are children
                      of Genetic Variation, and the term itself, Genetic Variation.
                    </p>
                  </div>

                  <div className={`tab-pane ${hash === '#cite_us' ? 'active show' : ''}`} id="cite_us">
                    <h2>How to cite us</h2>
                    <p>
                      If you use DISGENET in your work, please cite our URL{' '}
                      <a href="https://www.disgenet.com/" target="_blank" rel="noreferrer">
                        {'https://www.disgenet.com'}
                      </a>{' '}
                      and our latest publication:
                    </p>
                    <p>
                      Piñero, J., Ramírez-Anguita, J. M., Saüch-Pitarch, J., Ronzano, F., Centeno, E., Sanz,
                      F., & Furlong, L. I. (2020). The DisGeNET knowledge platform for disease genomics: 2019
                      update. Nucleic acids research, 48(D1), D845-D855.{' '}
                      <a href="https://doi.org/10.1093/nar/gkz1021" target="_blank" rel="noreferrer">
                        {'https://doi.org/10.1093/nar/gkz1021'}
                      </a>
                    </p>
                  </div>

                  <div
                    className={`tab-pane ${hash === '#version_24.2' ? 'active show' : ''}`}
                    id="version_24.2"
                  >
                    <h2 className="version-number"> July 10th, 2024: Release of version 24.2 of DISGENET</h2>
                    <p>
                      {' '}
                      The current version of DISGENET (v24.2) contains 1,934,730 gene-disease associations
                      (GDAs), between 26,392 genes and 39,903 diseases and traits; 1,254,874 variant-disease
                      associations (VDAs), between 719,161 variants and 17,029 diseases and traits, and over
                      27,494,442 M disease-disease associations. DISGENET also contains more than 340,000
                      associations involving 12,000 diseases detected in animal models. This release provides
                      annotations for 4,032 chemicals associated with GDAs and VDAs. 1,529,620 publications
                      support the GDAs, and 180,533 publications support the VDAs. In total, DISGENET contains
                      a corpus of 1,539,056 publications that support the genotype-phenotype associations.
                    </p>
                    <h6>DATA:</h6>
                    <ul>
                      <li className="bullet-item">All data sources for associations were updated.</li>
                      <li className="bullet-item">Improvement in the NER for genes and diseases.</li>
                      <li className="bullet-item">
                        New data source: we obtain biomarkers associated with diseases from clinical trials
                        available in{' '}
                        <a href="https://www.clinicaltrials.gov" target="_blank" rel="noreferrer">
                          ClinicalTrials.gov
                        </a>
                      </li>
                      <li className="bullet-item">
                        “Chemical Effect”: classification of the effect of a chemical on the disease, provided
                        processing expert-curated resources that provide adverse effect information and
                        indications. By using the “Chemical Effect” attribute, you can select chemicals that
                        are used for the treatment of a disease (therapeutic) or lead to a disease (toxicity).
                        This attribute is available in the GDA and VDA Evidence tables with chemical
                        annotations.
                      </li>
                      <li className="bullet-item">
                        Disease-Disease associations: we applied a more stringent cut-off p-value to identify
                        DDAs (before was 10<sup>-3</sup> and now is 10<sup>-6</sup>)
                      </li>
                    </ul>
                    <h6>R PACKAGE :</h6>
                    <ul>
                      <li className="bullet-item">New release of the disgenet2r R package (1.1.2).</li>
                      <li className="bullet-item">
                        New data attribute “Chemical Effect” is available in the following functions:
                        gene2chemical, disease2chemical, variant2chemical , and chemical2evidence. These
                        functions return the field chemical_effect, with possible values toxicity, therapeutic
                        and other.
                      </li>
                    </ul>
                    <h6>Cytoscape App:</h6>
                    <ul>
                      <li className="bullet-item">New release of the DISGENET Cytoscape App (v8.0.0).</li>
                    </ul>
                  </div>

                  <div
                    className={`tab-pane ${hash === '#version_24.1' ? 'active show' : ''}`}
                    id="version_24.1"
                  >
                    <h2 className="version-number"> April 3rd, 2024: Release of version 24.1 of DISGENET</h2>
                    <p>
                      {' '}
                      The current version of DISGENET (v24.1) contains 1,793,304 gene-disease associations
                      (GDAs), between 26,090 genes and 39,797 diseases and traits; 1,201,698 variant-disease
                      associations (VDAs), between 704,086 variants and 16,774 diseases and traits, and over
                      45 M disease-disease associations. DISGENET also contains more than 290,000 associations
                      involving 12,000 diseases that have been detected in animal models. This release
                      provides annotations for 3,981 chemicals associated with GDAs and VDAs. 1,470,167
                      publications support the GDAs, and 173,659 publications support the VDAs. In total,
                      DISGENET contains a corpus of 1,479,286 publications that support the genotype-phenotype
                      associations.
                    </p>
                    <h6>DATA:</h6>
                    <ul>
                      <li className="bullet-item">
                        We change the notation of the release versions. From now on, the first 2 digits will
                        represent the year, and the following digits indicate if it is the first, second,
                        third, or fourth release of the year. Therefore, the current release is v24.1 (the
                        first release of the calendar year 2024).
                      </li>
                      <li className="bullet-item">All data sources for associations were updated.</li>
                      <li className="bullet-item">gnomAD data updated to version 4.0.0.</li>
                      <li className="bullet-item">Disease, Gene, and Chemical dictionaries were updated.</li>
                      <li className="bullet-item">
                        We performed curation of Disease and Gene dictionaries to remove the ambiguity of
                        concepts. In the case of the Disease dictionary, we also performed a curation that
                        resulted in the removal of approx. 1K concepts that were not proper diseases or
                        phenotypes (e.g. “Folate-dependent fragile site at Xq28”), were very general (e.g.
                        “Communicable diseases”), or were diseases caused by external factors such as a
                        consequence of surgical procedures or burns (e.g. “Burn injury”).
                      </li>
                      <li className="bullet-item">
                        “Chemical Effect”: classification of the effect of a chemical on the disease, provided
                        processing expert-curated resources that provide adverse effect information and
                        indications. By using the “Chemical Effect” attribute, you can select chemicals that
                        are used for the treatment of a disease (therapeutic) or lead to a disease (toxicity).
                        This attribute is available in the GDA and VDA Evidence tables with chemical
                        annotations.
                      </li>
                      <li className="bullet-item">
                        Incorporated md5 file for checking the integrity of the database zipped files for
                        users that download the database dump.
                      </li>
                    </ul>

                    <h6>REST API:</h6>
                    <ul>
                      <li className="bullet-item">
                        New data attribute “Chemical Effect” is available in the following endpoints: GDA
                        evidence, VDA evidence.
                      </li>
                    </ul>

                    <h6>R PACKAGE :</h6>
                    <ul>
                      <li className="bullet-item">New release of the disgenet2r R package (1.0.6).</li>
                      <li className="bullet-item">
                        New data attribute “Chemical Effect” is available in the following functions:
                        gene2chemical, disease2chemical, variant2chemical , and chemical2evidence.
                      </li>
                    </ul>
                  </div>

                  <div className={`tab-pane ${hash === '#version_24' ? 'active show' : ''}`} id="version_24">
                    <h2 className="version-number">
                      {' '}
                      December 27th, 2023: Release of version 24 of DISGENET
                    </h2>
                    <p>
                      The current version of DISGENET (v24) contains 1,770,610 gene-disease associations
                      (GDAs), between 25,992 genes and 39,644 diseases and traits; 1,119,130 variant-disease
                      associations (VDAs), between 616,660 variants and 16,605 diseases and traits, and over
                      45 M disease-disease associations.DISGENET also contains more than 310,000 associations
                      involving 13,000 diseases that have been detected in animal models. This release
                      provides annotations for 3,015 chemicals associated with GDAs and VDAs. 1,425,789
                      publications support the GDAs, and 158,269 publications support the VDAs. In
                      total,DISGENET contains a corpus of 1,434,210 publications that support the
                      genotype-phenotype associations.
                    </p>
                    <h5>Release info:</h5>
                    <ul>
                      <li className="bullet-item">New data source: Rat Genome Database</li>
                      <li className="bullet-item">
                        17% increase in the number of GDAs compared to v21: more than 260K new GDAs available
                      </li>
                      <li className="bullet-item">
                        Increased coverage of genes and diseases/phenotypes: more than 1.3K new genes and 3.2K
                        diseases and phenotypes incorporated in the database
                      </li>
                      <li className="bullet-item">
                        New attributes for variants: we provide the SIFT and PolyPhen scores for pathogenicity
                        for each variant
                      </li>
                      <li className="bullet-item">
                        New features of disgenet2r R package (1.0.3):
                        <li className="bullet-item">
                          New features:
                          <li className="bullet-item">
                            Queries with functions disease2variant, chemical2variant, and chemical2vda now can
                            receive SIFT and POLYPHEN ranges
                          </li>
                        </li>
                        <li className="bullet-item">
                          Documentation:
                          <li className="bullet-item">NEWS.md file was added to document package changes</li>
                          <li className="bullet-item">
                            All functions that use the parameter database now have all data sources, including
                            RGD and MGD, that are new sources
                          </li>
                        </li>
                        <li className="bullet-item">
                          Bug fixes:
                          <li className="bullet-item">
                            Functions that use the parameter database that query variant endpoints now throw
                            an error if a database is not GWASCAT, CLINVAR, UNIPROT, TEXTMINING_HUMAN,
                            CURATED, or ALL
                          </li>
                        </li>
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`tab-pane ${hash === '#version_23.1' ? 'active show' : ''}`}
                    id="version_23.1"
                  >
                    <h2 className="version-number">
                      October 26th, 2023: Release of version 23.1 of DISGENET
                    </h2>
                    <p>
                      This release corresponds to the same data as in <a href="#version_23">release v23</a>,
                      but we have incorporated the following fields in the database dump files:
                    </p>
                    <h6>GDA SUMMARY TABLE:</h6>
                    <ul>
                      <li className="bullet-item">
                        N_DISEASES_GENE_ALL: Number of diseases associated to the gene in ALL data.
                      </li>
                      <li className="bullet-item">
                        N_VARIANTS_GENE_ALL: Number of variants associated to the gene in ALL data.
                      </li>
                      <li className="bullet-item">
                        N_GENES_DISEASE_ALL: Number of genes associated to the disease in ALL data.
                      </li>
                      <li className="bullet-item">
                        N_VARIANTS_DISEASE_ALL: Number of variants associated to the disease in ALL data.
                      </li>
                      <li className="bullet-item">
                        N_PMIDS_GDA_ALL: Number of publications supporting the gene-disease association in ALL
                        data.
                      </li>
                      <li className="bullet-item">
                        N_VARIANTS_GDA_ALL: Number of variants for the gene-disease association in ALL data.
                      </li>
                    </ul>

                    <h6>VDA SUMMARY TABLE:</h6>
                    <ul>
                      <li className="bullet-item">
                        N_DISEASES_VARIANT_ALL: Number of diseases associated to the variant in ALL data
                        (Integer).
                      </li>
                      <li className="bullet-item">
                        N_GENES_DISEASE_ALL: Number of genes associated to the disease in ALL data (Integer).
                      </li>
                      <li className="bullet-item">
                        N_VARIANTS_DISEASE_ALL: Number of variants associated to the disease in ALL data
                        (Integer).
                      </li>
                      <li className="bullet-item">
                        N_PMIDS_VDA_ALL: Number of publications supporting the variant-disease association in
                        ALL data (Integer).
                      </li>
                    </ul>
                    <h6>DDA TABLE:</h6>
                    <ul>
                      <li className="bullet-item">
                        ASSOC_RELATION: The Unified Medical Language System®(UMLS) Metathesaurus Semantic
                        Relationship between the concepts (String).
                      </li>
                    </ul>
                    <h6>GDA EVIDENCE TABLE:</h6>
                    <ul>
                      <li className="bullet-item">
                        ASSOC_PM_JOURNAL: Journal for the publication supporting the gene-disease association
                        (String).
                      </li>
                      <li className="bullet-item">
                        ASSOC_PM_JOURNAL_ABB: Journal Abbreviation for the publication supporting the
                        gene-disease association (String).
                      </li>
                    </ul>
                    <h6>VDA EVIDENCE TABLE:</h6>
                    <ul>
                      <li className="bullet-item">
                        ASSOC_PM_JOURNAL: Journal for the publication supporting the variant-disease
                        association (String).
                      </li>
                      <li className="bullet-item">
                        ASSOC_PM_JOURNAL_ABB: Journal Abbreviation for the publication supporting the
                        variant-disease association (String).
                      </li>
                    </ul>
                    <h6>GENE TABLE:</h6>
                    <ul>
                      <li className="bullet-item">GENE_FULL_NAME: Gene Full Name (String).</li>
                      <li className="bullet-item">
                        GENE_NCBI_TYPE: Gene NCBI type (i.e. protein-coding, ncRNA, tRNA, ... String).
                      </li>
                      <li className="bullet-item">
                        PATHWAY_IDS: The identifier of the top level category pathway from Reactome
                        (https://reactome.org/)(List of identifiers separated by the separator string (3
                        chars) _*_).
                      </li>
                      <li className="bullet-item">
                        PATHWAY_NAMES: The name of the top level category from Reactome
                        (https://reactome.org/). When more than one top level category pathway is associated
                        to the gene, a list of top level category names separated by the separator string (3
                        chars) _*_ is provided. A list of the same size will be present in the
                        PROTEIN_CLASS_IDS column: the identifier of each top level category name of this list
                        can be retrieved at the same index in the list of top level categoy indentifiers
                        included in the PATHWAY_IDS column (List of identifiers separated by the separator
                        string (3 chars) _*_).
                      </li>
                    </ul>
                    <h6>dbSNP DATA TABLE:</h6>
                    <ul>
                      <li className="bullet-item">
                        AF_EXOME: Allelic Frequency of the variant from the GNOMAD exomes information (list of
                        Doubles, -1 codes for no information).
                      </li>
                      <li className="bullet-item">
                        AF_GENOME: Allelic Frequency of the variant from the GNOMAD genomes information (list
                        of Doubles, -1 codes for no information).
                      </li>
                    </ul>
                  </div>
                  <div className={`tab-pane ${hash === '#version_23' ? 'active show' : ''}`} id="version_23">
                    <h2 className="version-number">
                      {' '}
                      September 27th, 2023: Release of version 23 of DISGENET
                    </h2>
                    <p>
                      The current version of DISGENET (v23) contains 1,729,483 gene-disease associations
                      (GDAs), between 25,869 genes and 39,141 diseases and traits; 1,117,115 variant-disease
                      associations (VDAs), between 617,724 variants and 16,533 diseases and traits, and over
                      43M disease-disease associations.DISGENET also contains more than 1,7 M associations
                      involving 14,000 diseases that have been detected in animal models. This release
                      provides annotations for 3,507 chemicals associated with GDAs and VDAs. 1,399,652
                      publications support the GDAs, and 156,684 publications support the VDAs. In
                      total,DISGENET contains a corpus of 1,408,123 publications that support the
                      genotype-phenotype associations.
                    </p>
                    <h5>Release info:</h5>
                    <ul>
                      <li className="bullet-item">New data source: Mouse Genome Database</li>
                      <li className="bullet-item">
                        10% increase in the number of GDAs: more than 175K new GDAs available
                      </li>
                      <li className="bullet-item">
                        Increased coverage of genes and diseases/phenotypes: almost 2.5K new diseases and
                        phenotypes incorporated into the database
                      </li>
                      <li className="bullet-item">Changes in the Association type ontology</li>
                      <li className="bullet-item">
                        {' '}
                        New features in the disgenet2r package:
                        <li className="bullet-item">
                          Changes in the plot function to enable visualization by DiseaseClass and
                          ProteinClass both in networks and heatmaps.
                        </li>
                        <li className="bullet-item">Heatmaps available for download as png and json files</li>
                        <li className="bullet-item">
                          Customization of network plots using the visNetwork package.
                        </li>
                        <li className="bullet-item">Retrieval of DDAs by relationship types.</li>
                      </li>
                    </ul>
                  </div>

                  <div className={`tab-pane ${hash === '#version_22' ? 'active show' : ''}`} id="version_22">
                    <h2 className="version-number"> July 5th, 2023: Release of version 22 of DISGENET</h2>
                    <p>
                      The current version of DISGENET (v22) contains 1,552,626 gene-disease associations
                      (GDAs), between 25,474 genes and 36,675 diseases and traits; 1,115,471 variant-disease
                      associations (VDAs), between 610,272 variants and 16,168 diseases and traits, and over
                      32,7 M disease-disease associations.DISGENET also contains more than 250,000
                      associations involving 12,000 diseases that have been detected in animal models. This
                      release provides annotations for 3,173 chemicals associated with GDAs and VDAs.
                      1,107,571 publications support the GDAs, and 155,495 publications support the VDAs. In
                      total,DISGENET contains a corpus of 1,160,823 publications that support the
                      genotype-phenotype associations.
                    </p>
                    <ul>
                      <li className="bullet-item">
                        More than 40K GDAs and VDAs are available, increasing the coverage of genes, variants
                        and diseases.
                      </li>
                      <li className="bullet-item">
                        New feature for DDAs: semantic relations from the UMLS between diseases
                      </li>
                      <li className="bullet-item">
                        New features on the REST API:
                        <li className="bullet-item">
                          New endpoint to search for chemicals, to retrieve information on number of GDAs,
                          VDAs and supporting publications.
                        </li>
                        <li className="bullet-item">
                          Search by chemical available on the endpoints GDA summary & evidence, VDA summary &
                          evidence.
                        </li>
                      </li>
                      <li className="bullet-item">
                        New features on the disgenet2r package:
                        <li className="bullet-item">
                          New functions to search for chemicals and their associated GDAs and VDAs New
                          functions to search for chemicals and their associated genes, variants and diseases.
                        </li>
                        <li className="bullet-item">
                          Different visualizations available for the new functions.
                        </li>
                      </li>
                    </ul>
                  </div>

                  <div className={`tab-pane ${hash === '#version_21' ? 'active show' : ''}`} id="version_21">
                    <h2 className="version-number">March 30th, 2023: Release of version 21 of DISGENET</h2>
                    <p>
                      The current version of DISGENET (v21) contains 1,509,314 gene-disease associations
                      (GDAs), between 24,613 genes and 0 diseases and traits; 1,070,721 variant-disease
                      associations (VDAs), between 584,693 variants and 16,010 diseases and traits, and over
                      32 M disease-disease associations.DISGENET also contains more than 240,000 associations
                      involving 12,000 diseases detected in animal models. This release provides annotations
                      for 3,174 chemicals associated with GDAs and VDAs. The GDAs are supported by 1,105,030
                      publications, and the VDAs by 141,088 publications.
                    </p>
                    <ul>
                      <li className="bullet-item">
                        All data sources updated, including the disease and gene ontologies.
                      </li>
                      <li className="bullet-item">
                        New data source: PheWas Catalog
                        <span>
                          <a target="_blank" rel="noreferrer" href="https://phewascatalog.org/">
                            {' '}
                            (https://phewascatalog.org/)
                          </a>
                          .
                        </span>
                      </li>
                      <li className="bullet-item">
                        Information on chemicals associated with GDAs and VDAs: we identify mentions of
                        chemicals by text mining within the publications that support the GDAs and VDAs.
                      </li>
                      <li className="bullet-item">
                        New features on the REST API:
                        <li className="bullet-item">
                          Search by GoF/LoF variations and consequence type in the “VDA evidence” endpoint.
                        </li>
                        <li className="bullet-item">
                          Search by consequence type in the “VDA summary” endpoint.
                        </li>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={`tab-pane ${hash === '#version_20.1' ? 'active show' : ''}`}
                    id="version_20.1"
                  >
                    <h2 className="version-number">
                      January 23th, 2023: Release of version 20.1 of DISGENET
                    </h2>
                    <p>
                      The current version of DISGENET (v20.1) contains 1,516,952 gene-disease associations
                      (GDAs), between 24,642 genes and 34,124 diseases and traits; 1,009,638 variant-disease
                      associations (VDAs), between 578,771 variants and 15,185 diseases and traits, and over
                      30,421,348 M disease-disease associations.DISGENET also contains more than 250,000
                      associations involving 11,000 diseases detected in animal models. This release provides
                      annotations for 3,937 chemicals associated with GDAs and VDAs.
                    </p>
                    <ul>
                      <li className="bullet-item">
                        New information on chemicals associated with GDAs and VDAs: we identify mentions of
                        chemicals by text mining within the publications that support the GDAs and VDAs.
                      </li>
                      <li className="bullet-item">
                        New attribute for genes: annotations to pathways from Reactome.
                      </li>
                      <li className="bullet-item">
                        New attribute for VDAs: annotation of the effect of the variant on the phenotype, such
                        as gain of function (GoF) or loss of function (LoF) mutations. This information is
                        obtained by text mining.
                      </li>
                    </ul>
                  </div>

                  <div className={`tab-pane ${hash === '#version_20' ? 'active show' : ''}`} id="version_20">
                    <h2 className="version-number">
                      November 3rd, 2022: Release of version 20.0 of DISGENET
                    </h2>
                    <p>
                      The current version of DISGENET (v20) contains 1,517,357 gene-disease associations
                      (GDAs), between 24,643 genes and 34,158 diseases, disorders, traits, and clinical or
                      abnormal human phenotypes, 1,010,102 variant-disease associations (VDAs), between
                      578,958 variants and 15,209 diseases, traits, and phenotypes and over 47 M
                      disease-disease associations.DISGENET also contains more than 250,000 associations
                      involving 11,000 diseases that have been detected in animal models.
                    </p>
                    <ul>
                      <li className="bullet-item">All data sources updated</li>
                      <li className="bullet-item">
                        Increased coverage for variants: 13,900 new variants, 40,000 new VDAs compared to the
                        previous release
                      </li>
                      <li className="bullet-item">30,000 new GDAs</li>
                      <li className="bullet-item">
                        New: reported odds ratio or beta-coefficient associated with the GWAS study are now
                        available
                      </li>
                      <li className="bullet-item">
                        New: disease-disease associations can be filtered by p values of the Jaccard index of
                        genes and variants
                      </li>
                      <li className="bullet-item">
                        New feature: visualization of the data is available (bar plots, heatmaps, and
                        networks)
                      </li>
                    </ul>
                  </div>

                  <div className={`tab-pane ${hash === '#version_19' ? 'active show' : ''}`} id="version_19">
                    <h2 className="version-number">June 27th, 2022: Release of version 19.0 of DISGENET</h2>
                    <p>
                      The current version of DISGENET (v19) contains 1,483,670 gene-disease associations
                      (GDAs), between 24,249 genes and 34,041 diseases, disorders, traits, and clinical or
                      abnormal human phenotypes and 968,761 variant-disease associations (VDAs), between
                      564,982 variants and 15,059 diseases, traits, and phenotypes and over 46 M
                      disease-disease associations.DISGENET also contains more than 260,000 associations
                      involving 11,000 diseases that have been detected in animal models.
                    </p>
                    <ul>
                      <li className="bullet-item">All data sources updated</li>
                      <li className="bullet-item">
                        Increased coverage for variants: 12,000 new variants, 35,000 new VDAs compared to the
                        previous release
                      </li>
                      <li className="bullet-item">100,000 new GDAs</li>
                      <li className="bullet-item">
                        New: searches by aminoacid-position are now available for variants
                      </li>
                      <li className="bullet-item">New: now users can filter genes using the gene type!</li>
                      <li className="bullet-item">
                        New: links to clinical trials are now available for genes and diseases
                      </li>
                    </ul>
                  </div>
                  <div className={`tab-pane ${hash === '#version_18' ? 'active show' : ''}`} id="version_18">
                    <h2 className="version-number">March 15th, 2022: Release of version 18.0 of DISGENET</h2>
                    <p>
                      The version v18 of DISGENET contains 1,371,161 gene-disease associations (GDAs), between
                      23,111 genes and 32,630 diseases, disorders, traits, and clinical or abnormal human
                      phenotypes and 933,409 variant-disease associations (VDAs), between 552,680 variants and
                      14,626 diseases, traits, and phenotypes, and over 41 M disease-disease associations.
                      This new release also contains more than 200,000 associations involving 10,000 diseases
                      that have been detected in animal models.
                    </p>

                    <p>
                      Our database now summarizes the disease genomics knowledge from over 1M scientific
                      publications by mining 30M publications.
                    </p>

                    <ul>
                      <li className="bullet-item">All data sources updated</li>
                      <li className="bullet-item">
                        Increased coverage for variants: 28% more variants, 23% more VDAs compared to the
                        previous release
                      </li>
                      <li className="bullet-item">13% more pieces of evidence for GDAs</li>
                    </ul>
                  </div>
                  <div className={`tab-pane ${hash === '#version_17' ? 'active show' : ''}`} id="version_17">
                    <h2 className="version-number">
                      December 13th, 2021: Release of version 17.4 of DISGENET
                    </h2>
                    <ul>
                      <li className="bullet-item">More variants with allelic frequency information</li>
                      <li className="bullet-item">
                        New: information on loss-of-function and gain-of-function variants associated with
                        diseases{' '}
                      </li>
                    </ul>
                    <h2 className="version-number">
                      November 26th, 2021: Release of version 17.3 of DISGENET
                    </h2>
                    <ul>
                      <li className="bullet-item">
                        New: now you can search using Ensembl gene identifiers in the web interface, the
                        disgenet2r package, and the REST API
                      </li>
                    </ul>

                    <h2 className="version-number">November 22th, 2021: Release of the web interface</h2>
                    <ul>
                      <li className="bullet-item">
                        New: you can now exploreDISGENET using our new web interface, with search and
                        filtering features{' '}
                      </li>
                    </ul>
                    <h2 className="version-number">November 11th, 2021: Release of version 17 of DISGENET</h2>
                    <p>
                      The version 17 of DISGENET contains 1,256,954 gene-disease associations between 22,703
                      genes and 31,781 diseases, disorders, traits, and clinical or abnormal human phenotypes,
                      648,990 variant-disease associations between 375,441 variants and 14,276 diseases,
                      traits, and phenotypes and 36,040,896 disease-disease associations. DISGENET contains
                      information that comprises 855,346 scientific publications.
                    </p>

                    <ul>
                      <li className="bullet-item">All data sources updated</li>
                      <li className="bullet-item">
                        New association types: chemical response, protective variant, and variant of uncertain
                        significance
                      </li>
                    </ul>
                  </div>

                  <div className={`tab-pane ${hash === '#version_16' ? 'active show' : ''}`} id="version_16">
                    <h2 className="version-number">September 16th, 2021: New release of the REST API!</h2>
                    <ul>
                      <li className="bullet-item">
                        New endpoints for the retrieval of attributes for genes, diseases, variants and
                        publications
                      </li>
                      <li className="bullet-item">
                        New endpoints to retrieve the summary and the evidences for the associations (GDAs and
                        VDAs)
                      </li>
                      <li className="bullet-item">
                        Free text search support in the gene and disease entity endpoints, useful to recover
                        database identifiers from free text expressions (e.g., to obtain the UMLS identifiers
                        for the string “schizophrenia”)
                      </li>
                      <li className="bullet-item">
                        Protein class information added in the response of the GDA endpoints (summary and
                        evidence)
                      </li>
                    </ul>

                    <h2 className="version-number">August 3rd, 2021: Release of the disgenet2r package </h2>
                    <p>
                      The package contains a set of functions to retrieve, visualize and expandDISGENET data.
                      It allows to:
                    </p>
                    <ul>
                      <li className="bullet-item">
                        Search by gene or multiple genes, using different identifiers
                      </li>
                      <li className="bullet-item">
                        Search one disease, or multiple diseases, using different identifiers
                      </li>
                      <li className="bullet-item">Perform different operation with disease vocabularies</li>
                      <li className="bullet-item">
                        Visualize the results of your queries using interactive networks, heatmaps, and other
                        types of plots
                      </li>
                    </ul>

                    <h2 className="version-number">July 20th, 2021: Release of version 16 of DISGENET</h2>
                    <p>
                      The version 16.0 of DISGENET contains 1,203,188 gene-disease associations, between 22148
                      genes and 31107 diseases, disorders, traits, and clinical or abnormal human phenotypes,
                      and 408,152 variant-disease associations, between 203,964 variants and 13,877 diseases,
                      traits, and phenotypes, and over 34,000,000 disease-disease associations.DISGENET
                      contains information that comprises 842,987 scientific publications.
                    </p>
                  </div>

                  <div className={`tab-pane ${hash === '#version_15' ? 'active show' : ''}`} id="version_15">
                    <h2 className="version-number">June 23th, 2021: Release of version 15 of DISGENET</h2>
                    <p>
                      The version 15.0 of DISGENET contains 1,191,107 gene-disease associations, between
                      22,079 genes and 30,648 diseases, disorders, traits, and clinical or abnormal human
                      phenotypes, 370,769 variant-disease associations, between 181,493 variants and 13,449
                      diseases, traits, and phenotypes, and over 34,000,000 disease-disease associations.
                      DISGENET contains information that comprises 846,941 scientific publications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MathJaxContext>
  );
}
export default AboutContent;
export {
  jaccardGda,
  jaccardVda,
  gdaScore,
  gdaScore1,
  gdaScore2,
  gdaScore3,
  gdaScore4,
  DiseaseSpecificityIndex,
  DiseasePleiotropyIndex,
  vdaScore,
  vdaScore1,
  vdaScore2,
  evidenceIndex,
};
