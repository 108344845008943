import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader';
import PricePlan from '../Components/Pricings/PricePlanes';
import Footer from '../Components/Home/Footer';

function Plans() {
  return (
    <React.Fragment>
      <CommonHeader />
      <PricePlan />
      <Footer />
    </React.Fragment>
  );
}

export default Plans;
