import React from 'react';
import SignUpForm from './SignUpForm';
import Testimony from './Testimony';
import Feature from './ Feature';

function ApplyAcademic() {
  return (
    <React.Fragment>
      <div className="container">
        <div className="pt-4 d-flex flex-row">
          <div className="apply-div">
            <h1 className="pb-2">Accelerate Your Research With The Most Reliable & Extensive Database</h1>
            <h6>
              <strong>GET YOUR FREE ACADEMIC LICENSE</strong>
            </h6>
            <h6 className="mt-4">
              Access all fundamental bioinformatics information needed for academic research, including
              well-known genes, diseases, and pathways <strong>free of charge.</strong>
            </h6>
            <div>
              <img
                className=""
                src="../../../static/images/disgenetpc.png"
                alt="logo disgenet"
                style={{
                  width: '650px',
                }}
              />
            </div>
          </div>
          <div className="">
            <SignUpForm />
          </div>
        </div>
        <div className="p-5">
          <Testimony />
        </div>
        <div className="benefits">
          <Feature />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ApplyAcademic;
