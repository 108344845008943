import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader.jsx';
import AboutContent from '../Components/About/AboutContent.jsx';
import Footer from '../Components/Home/Footer.jsx';
import $ from 'jquery';

export default function About() {
  $('.popover').hide();

  return (
    <React.Fragment>
      <title> DISGENET: Database Information</title>

      <meta
        name="description"
        content="Information about database statistics, attributes and metrics."
        data-rh="true"
      />

      <link rel="canonical" href="/About" />

      <CommonHeader />
      <AboutContent />
      <Footer />
    </React.Fragment>
  );
}
