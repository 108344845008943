import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-community/index.css';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAppContext } from '../../Context/Context.jsx';
import buildResultEndpoint from '../Utils/buildResultEndpoint.jsx';
import { getSizes, setSizesColumns, filterTypes } from './SetupTablesResources.jsx';
import fetchEndpoint from '../../Services/fetchEndpoint.jsx';
import Filters from './Filters.jsx';
import ErrorMessage from '../UI/ErrorMessage.jsx';

function Grid({
  columns,
  columnVisibilityModel,
  view,
  source,
  tab,
  searchItems,
  isEvidence,
  isMapping,
  setTotalResults,
  ...rest
}) {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    authorization,
    setIsAuthenticated,
    groups,
    setGroups,
    setAuthError,
    setAuthorization,
    restrictGridDisplay,
    restrictGridResultCount,
    restrictSources,
    restrictChemical,
    mappingContent,
  } = userAppContext();

  const [isSessionExpired, setIsSessionExpired] = useState(false); // todo: hacky quick fix for 403 handling in grid
  const [gridRef, setGridRef] = useState(null);
  // // pagination
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  // // sorting states
  const [orderByParam, setOrderByParam] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const defaultSortInfo = [];
  const sortInfo = useMemo(() => {
    if (groups === 'ROLE_TRIAL') {
      return orderByParam ? { dir: 1, name: orderByParam } : defaultSortInfo;
    }
    return orderByParam ? { dir: order, name: orderByParam } : defaultSortInfo;
  }, [groups, order, orderByParam]);

  const onSortInfoChange = useCallback(value => {
    const [sort] = Array.isArray(value) ? value : [value];
    setOrder(sort?.dir);
    setOrderByParam(sort?.name);
  }, []);

  const [defaultFilterValue, setDefaultFilterValue] = useState(Filters.filter(filter => !filter?.disable));
  const onFilterValueChange = useCallback(value => {
    setDefaultFilterValue(value);
  }, []);

   // Data
  const [dataSource, setDataSource] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const isTrial = useMemo(() => groups === 'ROLE_TRIAL', [groups]);

  useEffect(() => {
    if (!(view && source && tab && searchItems)) return;
    let urlEndpoint = buildResultEndpoint({
      view,
      source,
      tab,
      searchItems,
      isEvidence,
      isMapping,
      mappingContent,
      pageNum: restrictGridResultCount ? 0 : skip / limit,
      pageSize: limit,
      orderByParam,
      order,
      defaultFilterValue,
    });

    if (!isAuthenticated && skip !== 0) {
      setShowPopup(true);
      return;
    }

    if (isTrial && skip >= 30) {
      setShowPopup(true);
      return;
    }

    if (!urlEndpoint) return;
    urlEndpoint = !isAuthenticated ? `${urlEndpoint}&skey=unauth` : urlEndpoint;

    setDataSource(prev => {
      const result = fetchEndpoint(
        urlEndpoint,
        authorization,
        setAuthorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        prev,
      )
        .then(res => {
          const data = res?.payload;

          if (data) {
            // update result count state
            const totalCount = res?.paging ? res.paging.totalElements : 0;
            setTotalResults(totalCount);
            setIsSessionExpired(false);
            return Promise.resolve({ data, count: parseInt(totalCount) });
          }
          // if 403, session has expired
          if (res?.status === 403) setIsSessionExpired(true);
          // else,
          return Promise.resolve({ data: [], count: 0 });
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            return Promise.resolve({ data: [], count: 0 });
          } else {
            console.error('Error:', error);
            throw new Error(error);
          }
        });

      return result;
    });
  }, [view, source, tab, searchItems, skip, limit, order, orderByParam, defaultFilterValue]);
  //
  // ReactDataGrid customizations
  const renderColumnContextMenu = useCallback(menuProps => {
    const menuPropsLength = menuProps.items?.length;
    menuProps.items = menuProps.items.splice(0, menuPropsLength - 2);
  }, []);

  const scrollProps = useMemo(
    () => ({
      ...ReactDataGrid.defaultProps.scrollProps,
      autoHide: false,
      alwaysShowTrack: true,
    }),
    [],
  );

  const handleClosePopup = useCallback(() => {
    setShowPopup(false);
    setSkip(0);
  }, []);

  return (
    <>
      {(restrictGridDisplay && view === 'ALL') ||
      (restrictChemical && tab == 'CHEMICALS') ||
      (restrictChemical && view === 'CHEMICALS') ||
      (restrictChemical && isMapping && mappingContent?.tabSegment.includes('chem')) ||
      (restrictChemical && isMapping && mappingContent?.name === 'numberPmidsWithChemsIncludedInEvidence') ? (
        <ErrorMessage
          title={'This content is not available with your current profile'}
          subtitle={
            <a>
              <button onClick={() => navigate('/plans')}>
                View our <a href="/plans">plans</a>
              </button>
            </a>
          }
        />
      ) : isSessionExpired ? (
        <ErrorMessage title={'Session expired'} subtitle={<a>Please refresh the page</a>} />
      ) : (
        <ReactDataGrid
          idProperty="name"
          columns={columns}
          style={{ minHeight: 537 }}
          renderColumnContextMenu={renderColumnContextMenu}
          licenseKey={process.env.REACT_APP_DATA_GRID_LICENSE}
          filterTypes={filterTypes}
          pagination={true}
          enableFiltering={true}
          nativeScroll
          scrollProps={scrollProps}
          limit={limit}
          skip={skip}
          onLimitChange={setLimit}
          pageSizes={!isAuthenticated ? [10] : isTrial ? [10, 30] : [10, 20, 25, 40, 50, 100]} //todo
          columnUserSelect
          defaultFilterValue={defaultFilterValue}
          onFilterValueChange={onFilterValueChange}
          dataSource={dataSource}
          showColumnMenuFilterOptions={false}
          showColumnMenuGroupOptions={false}
          columnVisibilityModel={columnVisibilityModel}
          onSkipChange={setSkip}
          onReady={setGridRef}
          sortInfo={sortInfo}
          onSortInfoChange={onSortInfoChange}
          scrollTopOnFilter={false}
          scrollTopOnSort={false}
        />
      )}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            {!isAuthenticated && (
              <p>
                As an unregistered user you are viewing only 10 results ordered by score for each query. To
                view more, register for an account. Learn more about our <a href="/plans">plans here</a>.
              </p>
            )}
            {isTrial && (
              <p>
                Please note that the examples shown on this page only display the top-30 results ordered by
                score. To view more, <a href="/Profile-area">upgrade</a> your account. Learn more about our{' '}
                <a href="/plans">plans here</a>.
              </p>
            )}
            <button onClick={() => handleClosePopup(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}
// export default React.memo(Grid);
export default Grid;
