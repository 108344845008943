import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { userAppContext, searchContext } from '../Context/Context';
import { validateQueryParams } from '../Components/Utils/ValidateQueryParams';
import fetchEndpoint from '../Services/fetchEndpoint';
import updateParams from '../Components/Utils/UpdateParams';

import Home from '../Pages/Home';
import Contact from '../Pages/Contact';
import Privacy from '../Pages/PrivacyPolicy';
import CookiesPolicy from '../Pages/CookiesPolicy';
import AboutFaq from '../Pages/FAQ';
import Api from '../Pages/Api';
import ProfileArea from '../Pages/ProfileArea';
import NotFoundContent from '../Pages/NotFound';
import BadParamsContent from '../Pages/BadParams';
import About from '../Pages/About';
import LegalNotices from '../Pages/LegalNotices';
import AccountActivation from '../Pages/AccountActivation';
import Reset from '../Pages/Reset';
import Search from '../Pages/Search';
import LoginPage from '../Pages/LoginPage';
import Plans from '../Pages/Plans';
import RegisterPage from '../Pages/RegisterPage';
import HistoryPage from '../Pages/History';
import UpgradePlan from '../Components/User/UpgradePlan';
import ApplyTrialPage from '../Pages/ApplyTrialPage';
import ApplyAcademicPage from '../Pages/ApplyAcademicPage';

export const CustomRoutes = () => {
  const isFirstSearchRender = useRef(true);

  // The useEffects in this component
  // drive the internal navigation state of the app
  // based on the url params
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  // Context vars
  const {
    view,
    setView,
    source,
    setSource,
    tab,
    setTab,
    searchItems,
    setSearchItems,
    hasValidParams,
    setHasValidParams,
    setMappingContent,
  } = searchContext();
  const {
    authorization,
    isAuthenticated,
    setIsAuthenticated,
    setGroups,
    setAuthError,
    setAuthorization,
    restrictSources,
  } = userAppContext();

  useEffect(() => {
    // this effect handles navigation changes when searchItems changes
    // the states modeled for this are:
    // url (left side)
    // searchItems (right side)
    // 0 == 0 (Both are empty and eq)
    // n == n (Both have length > 0 and are equal)
    // 0 < n
    // n > 0
    // N > n
    // n < N
    if (!['/examples', '/search'].includes(location.pathname)) {
      // not a search url
      // do nothing
      return;
    }
    // The app drives the url so "previous" idents are the URL idents and new idents are the searchItems
    const prevUrlIdents = searchParams.get('idents')?.split('-');
    const newIdents = searchItems.map(item => item.id);
    let sortedOldIdents = prevUrlIdents ? [...prevUrlIdents].sort() : [];
    let sortedNewIdents = newIdents ? [...newIdents].sort() : [];

    // If url idents and searchItem.Ids are equal, everything in sync, do nothing
    // Covers 0 == 0 and n == n
    if (
      prevUrlIdents?.length === newIdents?.length &&
      sortedOldIdents?.every((v, i) => v === sortedNewIdents[i])
    ) {
      return;
    }

    // Typically on a first render, ie, first page load from outside the app:
    // the url will have idents but the app variables will not have been initialized yet
    // URL is ok and will be used to initialize the app
    // do nothing in this useEffect
    // covers n > 0
    if (isFirstSearchRender.current && prevUrlIdents?.length > 0 && searchItems.length === 0) {
      isFirstSearchRender.current = false;
      return;
    }

    // items have been added to searchItems
    // This only occurs when the "Search" button has been pressed
    // Any navigation changes or URL param updates are handled there
    // do nothing
    // covers 0 < n and n < N
    if (prevUrlIdents?.length < searchItems.length) {
      return;
    }

    // searchItem(s) have been removed, update URL
    // covers N > n and n > 0
    if (prevUrlIdents?.length > searchItems.length) {
      let newParams;
      if (searchItems.length != 0) {
        // If there are still items in searchItems
        newParams = updateParams({ searchItems: searchItems });
      } else {
        // final searchItem removed, redirect to browse of current view
        const oldView = searchParams.get('view');
        newParams = updateParams({ view: 'ALL', tab: oldView, searchItems: [{ id: 'ALL' }] });
      }
      navigate({ pathname: location.pathname, search: newParams });
    }
  }, [searchItems]);

  useEffect(() => {
    // this effect handles the mappingResults state on URL changes
    // close mappingResults when changing base search
    // Changing source should not reset mappingContent
    // collect url state and bypass deactivation if base search has changed
    const hasSearchParams = searchParams.toString() !== '';
    if (hasSearchParams && ['/examples', '/search'].includes(location.pathname)) {
      const newView = searchParams.get('view');
      const newTab = searchParams.get('tab');

      if (newView === view && newTab === tab) return;
    }
    // deactivate mappingResults
    setMappingContent(prevContent => ({ ...prevContent, isActive: false }));
  }, [window.location.pathname, window.location.search]);

  useEffect(() => {
    // this effect handles the mappingResults state on searchItem changes:
    // any change to searchItems means base search has changed, deactivate mappings:

    setMappingContent(prevContent => ({ ...prevContent, isActive: false }));
  }, [searchItems]);

  useEffect(() => {
    // This effect handles updating the internal state of the app based on the url params
    // The relevant internal variables are view, source, tab, and searchItems
    // and mappingContent also has some dependencies with the URL
    // the relevant url params are, respectively, view, source, tab, and idents

    // if navigating back to home, reset internal app
    if (location.pathname === '/') {
      setView('');
      setSource('');
      setTab('');
      setSearchItems([]);
      return;
    }

    // if on search or examples, update the app internal state
    const hasSearchParams = searchParams.toString() !== '';
    if (hasSearchParams && ['/examples', '/search'].includes(location.pathname)) {
      const newView = searchParams.get('view');
      const newSource = searchParams.get('source');
      const newTab = searchParams.get('tab');
      const newIdents = searchParams.get('idents'); // idents will be used to build newSearchItems

      if (!validateQueryParams(searchParams)) {
        setHasValidParams(false);
        // navigate(`/Error?${searchParams.toString()}`);
        navigate(`/Error`);
        return;
      }

      if (restrictSources) {
        // the scope of restictSources currently only limits:
        // Academic profiles to the 'CURATED' source
        if (restrictSources == 'CURATED' && newSource !== 'CURATED') {
          navigate({
            pathname: location.pathname,
            search: updateParams({ source: 'CURATED' }),
          });
          return;
        }
      }

      setHasValidParams(true);
      if (hasValidParams) {
        // if everything is valid, set internal state
        setView(newView);
        setSource(newSource);
        setTab(newTab);

        // setSearchItems has more edge cases to handle:
        const prevUrlIdents = searchItems.map(item => item.id).join('-');
        if (newIdents === 'ALL') {
          // 'ALL' view
          // todo: validateQueryParams shouldn't allow an ident besides all when view == 'ALL'
          return;
        } else if (newIdents && prevUrlIdents === newIdents) {
          // if searchItems and url idents match, reuse them
          return;
        } else {
          // Page navigation, refresh or url has been modified manually
          // and searchItems needs to be rebuilt
          const ids = newIdents.split('-');
          let newSearchItems = [];
          let fetchedPromises = ids.map(id => {
            let urlEndpoint = `api/search?pattern=${id}`;
            urlEndpoint = !isAuthenticated ? `${urlEndpoint}&skey=unauth` : urlEndpoint;
            return fetchEndpoint(
              urlEndpoint,
              authorization,
              setAuthorization,
              setIsAuthenticated,
              setGroups,
              setAuthError,
              // newView,
              // id,
            )
              .then(res => {
                const payload = res?.payload;
                if (payload) {
                  // similar logic to the rendering of sections in Dropdown.jsx
                  // the newView determines which category of search results we're interested in
                  const [key, data] = Object.entries(payload).find(
                    ([key, value]) => key === newView.toLowerCase(),
                  );
                  // and from search results, we select the first item that matches the id
                  let newItem;
                  if (data?.length === 1) {
                    newItem = data[0];
                  } else {
                    // loop through the entries of each object in data array and keep the first one whos value equals the id
                    newItem = data.find(entry => Object.values(entry).includes(id));
                  }
                  // If all else fails, use the first entry
                  if (!newItem) {
                    newItem = data[0];
                  }
                  // add the item to the newSearchItems array
                  newSearchItems.push(newItem);
                }
              })
              .catch(error => console.error('Error:', error));
          });

          Promise.all(fetchedPromises).then(() => {
            setSearchItems(newSearchItems);
          });
        }
      }
    }
  }, [location.pathname, location.search, hasValidParams]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Contact-us" element={<Contact />} />
      <Route path="/Privacy-policy" element={<Privacy />} />
      <Route path="/Cookies-policy" element={<CookiesPolicy />} />
      <Route path="/FAQ" element={<AboutFaq />} />
      <Route path="/About" element={<About />} />
      <Route path="/Activate" element={<AccountActivation />} />
      <Route path="/Legal" element={<LegalNotices />} />
      <Route path="/Profile-area" element={<ProfileArea />} />
      <Route path="/Tools" element={<Api />} />
      <Route path="/Reset" element={<Reset />} />
      <Route path="/search" element={<Search />} />
      <Route path="/examples" element={<Search />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/plans" element={<Plans />} />
      {/* <Route path="/register" element={<RegisterPage />} /> */}
      <Route path="/Error" element={<BadParamsContent />} />
      {/* <Route path="/history" element={<HistoryPage />} /> */}
      <Route path="/free-trial-apply" element={<ApplyTrialPage />} />
      <Route path="/academic-apply" element={<ApplyAcademicPage />} />
      <Route path="/*" element={<NotFoundContent />} />
    </Routes>
  );
};
