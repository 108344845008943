import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AcademicLicensePopUp({ onClose, onAcceptTerms }) {
  const popupRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="privacy-popup-container" ref={popupRef}>
      <div className="privacy-popup-content">
        <div id="printCopy" className="container txt-contenido">
          <div className="">
            <div className="">
              <h3 className="text-center">MEDBIOINFORMATICS SOLUTION TERMS AGREEMENT</h3>
            </div>
            <p>
              These MEDBIOINFORMATICS SOLUTION conditions (hereinafter, the "Conditions" and, together with
              the attached Annex, the "Agreement") between MedBioinformatics Solutions S.L. (hereinafter, the
              "Company", "we" or "us"), with registered office at Rambla de Cataluña 14, 08007, Barcelona,
              Spain and tax identification number B67590950 and the entity you represent ("Customer") will
              become effective upon notification of successful validation by the Company ("Effective Date").
              Company and Customer are hereinafter referred to individually as a "Party" and collectively as
              the "Parties". This Agreement sets forth the Parties' responsibilities for Company's provision
              of the Services and Customer's use of the Services (as defined below) through Customer's Account
              (as defined below).
            </p>
            <div>
              <h5 className="text-center">WHEREAS</h5>
              <div className="p-3">
                <p>
                  I. Company has developed the DISGENET knowledge platform on genomic medicine, which includes
                  a database that can be remotely accessed through a web (“Company Services”).
                </p>
                <p>
                  II. The Customer is interested in subscribing to the Company Services, in accordance with
                  the General Conditions attached hereto.{' '}
                </p>
                <p>
                  III. This <strong>“Agreement”</strong> establishes the terms and conditions that shall apply
                  to the Customer’s subscription to the Company Services.{' '}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h6>1. DEFINITIONS</h6>
            <p>
              <strong>Customer’s Users:</strong> means persons of the Customer staff authorized to access the
              Company Service or otherwise receive the Company Services, in accordance with the criteria set
              out in the Customer Validation Form.
            </p>
            <p>
              <strong>Company Services:</strong> means the online meta-database DISGENET, any update and
              upgrade of it and the data contained therein <strong>(“Content”)</strong> that can be accessed
              as SaaS mode.{' '}
            </p>
            <p>
              <strong>Intellectual and Industrial Property Rights:</strong> means inventions, patent
              applications, patents, logotype rights, author rights or copyright, trademarks, service marks,
              trade names, domain name rights, design rights, rights of creation and other rights of
              commercial secrets as well as all other intellectual and industrial property rights derived from
              these and all the forms of protection of a similar nature in any part of the world;
            </p>
            <p>
              <strong>SaaS mode:</strong> means the Company Service accessible remotely through the Company’s
              website.
            </p>
          </div>
          <div>
            <h6>2. SUBSCRIPTION TO SERVICES</h6>
            <p>
              <strong>2.1. Validation. </strong>To subscribe to the Company's Services, the Customer must
              accept this Agreement and complete the Customer Validation Form attached as Annex 1. The Company
              will consider the Customer's profile in order to make a decision on the provision of the
              Company's Services (“Validation process”). The Validation Process will take an estimated period
              of 5 working days within which the Company will freely decide whether the Customer is aligned
              with the Company's practices. A positive validation decision will be notified to Customer and
              will settle de Effective Date.
            </p>
            <p>
              <strong>2.2. Subscription.</strong> As from the Effective Date, the Company will make available
              to Customer the Company Services pursuant to the terms and for the purposes set forth in this
              Agreement.
            </p>
            <p>
              <strong>2.3. Authorisation.</strong> Subject to the terms of this Agreement, Company hereby
              grants Customer a non-exclusive, non-transferable right during the Term to access and use the
              Company Services. All rights not expressly granted to you in relation to the Company Services
              are reserved by the Company. Customer acknowledges that Company: (I) has complete and sole
              discretion over the selection and arrangement of Content available in the Company Service; (II)
              can move, delete, or update Content at any time for any reason and (III) make improvements
              and/or changes to the Company Service at any time without notice; provided in each case that the
              Company shall not substantially reduce the availability or functionality of the Company
              Services.
            </p>
            <p>
              <strong>2.4. Company Services access.</strong> After the Effective Date, Company will provide
              you with one or more login name/s and password/s as defined in the validation process{' '}
              <strong>(“Credentials”)</strong>, necessary for your Customer’s Users to use the Company
              Services. Customer agrees to use industry standard security measures to maintain the
              confidentiality of these Credentials.
            </p>
            <p>
              <strong>2.5. Restrictions.</strong> Except as expressly authorized for the purposes set forth in
              this Agreement, Customer:
            </p>
            <ul className="pl-4">
              <li>
                a) will use best efforts to ensure that only Customer’s Users access the Company Service, and
                it is not displayed or distributed in any way to any third party;
              </li>
              <li>
                b) shall not rent, lease, distribute, transfer, reproduce, display, sublicense, assign, make
                available, publish or timeshare the Company Service nor its Content and any portion thereof;
              </li>
              <li>
                c) will not reverse engineer or access the Company Services in order to (i) build a
                competitive product or service, (ii) build a product using similar ideas, features, functions
                or graphics of the Company Services, or (iii) copy any ideas, features, functions or graphics
                of the Company Services;
              </li>
              <li>
                d) will not use the Company Services on behalf of another individual or organization other
                than Customer;
              </li>
              <li>
                e) will take all reasonable steps to protect the Company Service from unauthorized access or
                use and will use best efforts to prevent third parties from obtaining Credentials, and will
                not attempt to gain unauthorized access to the Company Services or their related systems or
                networks; Customer will immediately inform Company of the loss or theft of any Credential
                and/or any actual or potential unauthorized access or use of the Company Service;
              </li>
              <li>
                f) will not use the Company Service or its content in a manner that could result in an illegal
                act that would contravene any laws and governmental rules and regulations that may be
                applicable to the usage of such services;
              </li>
              <li>
                g) will not impede, damage or impair the normal functioning of the Company Service or the
                rights of the Company or third parties; and
              </li>
              <li>
                h) will not copy, frame or mirror any part or Content of the Company Services, other than
                copying or framing on your own intranets or otherwise for the Customer’s own internal and
                noncommercial purposes as stated in clause 5.1;
              </li>
              <li>
                i) will not directly or indirectly export or transmit the Company Service (or any part
                thereof) to any country to which such export or transmission is restricted by any applicable
                regulation or statute (including USA, Canada and United Nations), without the prior written
                consent of the competent governmental authority, if necessary, and the Company.
              </li>
            </ul>
            <p style={{ border: '1px solid', paddingLeft: '2em', padding: '1em' }}>
              <strong>
                FOR THE AVOIDANCE OF DOUBT, COMPANY SERVICES MAY NOT BE INTEGRATED INTO A PRODUCT OR SERVICE,
                IF THIS INVOLVES A USE RESTRICTED IN CLAUSE 2.5, E.G. RESULTING AS OUTPUT FROM AN AI MODEL,
                MACHINE LEARNING OR ANY SIMILAR TECHNOLOGY THAT REPRESENTS A DISTRIBUTION OF COMPANY SERVICES.
              </strong>
            </p>
          </div>
          <div>
            <h6>3. TERM AND TERMINATION, SUSPENSION</h6>
            <p>
              <strong>3.1. Term.</strong>he term of this Agreement will commence on the Effective Date and
              last for the Term of one year, unless terminated earlier under this section of this Agreement.
              Parties may agree renewal for the same period.
            </p>
            <p>
              <strong>3.2. Termination.</strong>Company may terminate this Agreement if:
            </p>
            <ul className="pl-4">
              <li>
                (i) the Customer other Party materially breaches any of its representations, warranties or
                obligations under this Agreement.{' '}
              </li>
              <li>
                (ii) representations, warranties or obligations under this Agreement. (ii) Company decision.
                In this case Company will notify the Customer with a previous 30 calendar days.{' '}
              </li>
            </ul>
            <p>
              <strong>3.3. On termination.</strong> On termination for any reason, Customer shall delete any
              and all Content downloaded by the Company Services during the Term and certify the same in
              writing to the Company.
            </p>
            <p>
              <strong>3.4. Survival.</strong> Notwithstanding the foregoing, any restrictions, reservations of
              rights and confidentiality as set forth herein shall remain in full force and effect upon the
              termination of this Agreement.
            </p>
          </div>
          <div>
            <h6>4. COMPANY RIGHTS AND OBLIGATIONS</h6>
            <p>
              <strong>4.1. Service Levels.</strong> Subject to the terms of this Agreement (including, without
              limitation, Customer’s obligations), Company shall use commercially reasonable efforts to
              provide you with access to the Company Services, except for:
            </p>
            <ul className="pl-4">
              <li>(i) Equipment malfunctions beyond the Company’s reasonable control;</li>
              <li>
                (ii) Planned downtime (of which we shall give at least twelve (12) hours advance notice by
                email); or
              </li>
              <li>
                (iii) Any unavailability caused by circumstances beyond the Company’s reasonable control,
                including without limitation, acts of God, acts of government, floods, fires, earthquakes,
                civil unrest, acts of terror, strikes or other labour problems (other than those involving our
                employees), internet service provider failures or delays, or denial of service attacks, etc.
              </li>
            </ul>
            <p>
              <strong>4.2. Support.</strong> Company will provide support services to the maximum number of
              Customer’s Users indicated in the Statement of Service. Support services will respond upon
              contacting us at customerservice@disgenet.com.
            </p>
            <p>
              <strong>4.3. Auditing.</strong> Company may audit Customer’s compliance with the terms of this
              agreement, such <strong>audits</strong> may include requests for documents and information, and
              visits to Customer facilities (complying with Customer’s reasonable security requirements),
              limited to those necessary to verify compliance with the terms of this Agreement. Failure to
              reasonably comply with Company’s efforts to audit Customer’s compliance with this Agreement
              shall constitute a material breach of this Agreement.
            </p>
            <p>
              <strong>4.4. Suspension.</strong> If Company, in its sole discretion, believes that Customer has
              breached this Agreement, or that it has engaged in fraudulent activities, Company may take any
              and all steps it deems appropriate, including issuing a warning, conducting an investigation, or
              suspending any license and access to the Company Service. In addition to any other available
              remedies, Company may, at its sole discretion, seek specific performance or injunctive relief.
            </p>
          </div>
          <div>
            <h6>5. CUSTOMER RIGHTS AND OBLIGATIONS</h6>
            <p>
              <strong>5.1. Internal and noncommercial Use.</strong> Unless otherwise indicated herein, all
              access to and use of the Company Service are solely for Customer’s internal noncommercial
              purposes. This includes, but is not limited to, conducting academic research, scientific
              projects, and educational activities. The Customer agrees not to use the Company's Services for
              the purpose of direct economic benefit, such as selling data or developing commercial products.
              For the avoidance of doubt, the use of Company Service by the Customer for the provision of
              research or related services entrusted by a third party is prohibited.
            </p>
            <p style={{ border: '1px solid', paddingLeft: '2em', padding: '1em' }}>
              <strong>
                WE ENCOURAGE CUSTOMERS TO PUBLISH THEIR RESEARCH RESULTS CITING DISGENET WHEN APPLICABLE.
                THEREFORE, PLEASE NOTE THAT IS PROHIBITED FOR THE CUSTOMER TO PROVIDE SERVICES TO THIRD
                PARTIES BASED ON THE COMPANY SERVICES E.G. WHEN CUSTOMER IS ACTING AS A CONTRACTOR FOR A THIRD
                PARTY.
              </strong>
            </p>
            <p>
              <strong>5.2. Testimonials.</strong> From time to time Company may request and Customer shall
              share a testimony of their use of Company Services in order to be included on Company’s website,
              Social Media.
            </p>
            <p>
              <strong>5.3. Other rights and obligations.</strong> In addition to its other rights and
              obligations set out herein, you shall be responsible for maintaining and promptly updating any
              account information you have with us for accuracy and completeness. You shall also:
              <ul className="pl-4">
                <li>
                  (iv) be responsible for the technology environment and connections required to access the
                  Company Services; Customer shall establish industry standard security measures to protect
                  content downloaded by the Company Service, to prevent unauthorized access or use and
                  unauthorized or inadvertent disclosure of content of the Company Service;
                </li>
                <li>(v) be responsible for all Customer’s Users’ compliance with this Agreement;</li>
                <li>
                  (vi) use the Company Services in accordance with the any technical documentation that may be
                  provided, software license agreements, and applicable laws, rules, and regulations
                  (including, without limitation, export, data protection and privacy laws, rules, and
                  regulations); and
                </li>
                <li>
                  (vii) If any update of the Company Services takes place and it is made available to Customer
                  during the Term, use the latest version of that Company Services.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h6>6. FEES</h6>
            <p>
              <strong>6.1.</strong> Customer will not be charged fees for using the Company's Services that
              comply with the restrictions and limitations on use defined in this Agreement ("Free Uses"). For
              uses in excess of those defined in this Agreement as Free Uses, the Company will charge the
              applicable fees.
            </p>
          </div>

          <div>
            <h6>7. PROPRIETARY RIGHTS / MARKS</h6>
            <p>
              <strong>7.1.</strong> As between the Company and Customer, the Company shall own all right,
              title, and interest in and to the Company Services, including, without limitation, the specific
              design, programming, and structure of Company Services, as well as other rights and know-how
              related to Company Services (including any modifications or enhancements) and all associated
              rights under copyright, trademarks, and patents. Customer will not challenge the Company’s
              ownership of the Company Service nor its content. Customer will not alter or delete any
              copyright notices or trademarks included in the Company Service.
            </p>
            <p>
              <strong>7.2.</strong> Without conveying any right, title or interest, the Parties agree that the
              Company may make accurate informational references to Customer’s trade names, trademarks or
              service marks (collectively, the “Marks”) in connection with its performance of the Company
              Services, including, without limitation, references in promotional materials, notices, and
              advertisements for the whole Term and the subsequent 3 years, subject to the condition that the
              Company shall promptly cease any use of any Mark owned by Customer upon receipt of notice from
              the Customer to discontinue such use. In the event that the Company makes reference to any Mark
              that is inaccurate, or otherwise incorrect, then Customer may notify the Company of the
              necessary correction(s), and the Company shall implement such correction(s) as soon as is
              reasonably practicable.
            </p>
          </div>
          <div>
            <h6>8. CONFIDENTIALITY</h6>
            <p>
              <strong>8.1.</strong> Each party agrees that during the course of this Agreement, information
              that is confidential or proprietary may be disclosed to such party (the “Receiving Party”) by
              the other party (the “Disclosing Party”) (collectively, “Confidential Information”).
              Confidential Information also includes all and any data received by Customer in the course of
              using the Company Services. Confidential Information will not include information that the
              Receiving Party can demonstrate (i) is, as of the time of its disclosure, or thereafter becomes,
              part of the public domain through a source other than the Receiving Party; (ii) was known to the
              Receiving Party as of the time of its disclosure; (iii) is independently developed by the
              Receiving Party; or (iv) is subsequently learned from a third party not under a confidentiality
              obligation to the Disclosing Party.
            </p>
            <p>
              <strong>8.2.</strong> The Receiving Party will not use any Confidential Information of the
              Disclosing Party for any purpose not expressly permitted by this Agreement, and will disclose
              the Confidential Information of the Disclosing Party only to the employees or contractors or
              authorized third party users of the Receiving Party who have a need to know such Confidential
              Information for purposes of this Agreement and who are under a duty of confidentiality no less
              restrictive than the Receiving Party’s duty hereunder. The Receiving Party will protect the
              Disclosing Party’s Confidential Information from unauthorized use, access, or disclosure in the
              same manner as the Receiving Party protects its own confidential or proprietary information of a
              similar nature and with no less than reasonable care.
            </p>
            <p>
              <strong>8.3.</strong> The Receiving Party will be allowed to disclose Confidential Information
              of the Disclosing Party to the extent that such disclosure is: (i) approved in writing by the
              Disclosing Party, (ii) necessary for the Receiving Party to enforce its rights under this
              Agreement in connection with a legal proceeding; or (iii) required by law or by the order of a
              court or similar judicial or administrative body, provided that the Receiving Party notifies the
              Disclosing Party of such required disclosure promptly and in writing and cooperates with the
              Disclosing Party, at the Disclosing Party’s request and expense, in any lawful action to contest
              or limit the scope of such required disclosure.
            </p>
          </div>

          <div>
            <h6>9. DATA PROTECTION</h6>
            <p>
              <strong>9.1.</strong> Each Party shall comply with all applicable laws relating to privacy and
              data protection, including the EU General Data Protection Regulation (2016/679), and relevant
              national privacy laws as implemented in each jurisdiction (including, as applicable, the Spanish
              Organic Law 3/2018 on data protection and guarantee of digital rights), and any amending or
              replacement legislation from time to time (collectively and individually, “Data Protection
              Laws”).
            </p>
            <p>
              <strong>9.2.</strong> In accordance with Data Protection Laws, processing the personal data of
              the individuals mentioned in this Agreement, being identification and contact data (name,
              surname, position, email address and telephone) of the Parties’ signatories or representative,
              and technical or commercial contact persons, as well as the Customer’s Users, is necessary for
              the preparation and performance of this Agreement and for invoicing purposes, and more generally
              to manage our contractual and commercial relationship with the Customer and to inform it
              promptly about any aspect related to the Company Service provided or that can be performed by
              the Company in the future. This personal data will not be shared with any third party however,
              they may be processed by third-party service providers with whom the Parties have a contract for
              data processing according to the applicable regulations. The Parties will keep this personal
              data for the whole duration of the Agreement and (blocked) for the period prescribed by law for
              legal or administrative reasons.
            </p>
            <p>
              <strong>9.3.</strong> Each Party warrants it is authorised to submit this information and agrees
              to inform these persons of the possibility of exercising their rights of access, rectification,
              erasure and opposition, limitation, and restriction of processing in the terms established by
              the current legislation, by writing to the Company to dataprotection@disgenet.com or the
              Customer as indicated in the Customer Validation Form. With regard to the Company, those persons
              may, if they so wish, also file a complaint, if any, with the Spanish Agency for Data
              Protection. The Parties declare that the aforementioned data are correct and updated and
              undertakes to communicate the provisions of this clause to the aforementioned persons.
            </p>
            <p>
              <strong>9.4.</strong>The obligations set forth in this section will survive the expiration or
              termination of this Agreement.
            </p>
          </div>
          <div>
            <h6>10. WARRANTY; WARRANTY DISCLAIMER</h6>
            <p>
              <strong>10.1.</strong> Customer represents and warrants to the Company that (i) Customer has the
              power and authority to enter into and perform its obligations under this Agreement; and (ii) all
              information provided to Company for the purpose of entering into this Agreement is true,
              accurate and up-to-date and (iii) it complies and shall comply with all applicable laws and
              regulations that relate to anti-bribery and/or anti-corruption.
            </p>
            <p>
              <strong>10.2.</strong> The Company represents and warrants to Customer that (i) the Company has
              the power and authority to enter into and perform its obligations under this Agreement and to
              grant the rights granted by the Company under Section 1; (ii) the Company shall perform the
              Company Services in a workmanlike manner and with professional diligence and skill standard in
              the industry; (iii) the Company Services, throughout the Term, shall conform substantially to
              the provisions of this Agreement. Without prejudice to the foregoing, if any update of the
              Company Services takes place during the Term and is made available to Customer, the Company
              representations and warranties will only regard the latest version of the Company Services.
            </p>
            <p>
              <strong>10.3.</strong> Customer acknowledges and agrees that, except as stated in clause 10.2
              above, the Company exercises no control over, and accepts no responsibility for, the content of
              the information obtained by the Company from its data suppliers.
            </p>
            <p>
              <strong>
                10.4. Except for the express warranties stated in this section by the Company, to the maximum
                extent permitted by applicable law, the services and any technology or access to the same that
                the Company supplies hereunder are provided “as is” and neither the Company, its employees,
                affiliates, agents, suppliers, third-party information providers, merchants, licensors nor the
                like make any warranties of any kind, either expressed or implied, including, but not limited
                to, warranties of satisfactory quality or fitness for a particular purpose or non-infringement
                with regard to the content of the Company Service. Neither the Company, its employees,
                affiliates, agents, third-party information providers, licensors or the like warrant that the
                Company Services will be uninterrupted or error free or that the Content is complete or free
                from errors; nor do any of them make any warranty as to the results that may be obtained from
                the use of the Company Services or as to the accuracy, reliability or content of any
                information or services contained in or provided through the Company Services. The Company is
                not liable for the content or loss of any data transferred either to or from Customer or
                stored by Customer.
              </strong>
            </p>
            <p>
              <strong>10.5.</strong> Customer will indemnify, defend, and hold the Company and its officers,
              directors, agents, and employees harmless from and against any Losses arising from any
              third-party against the Company to the extent based on (i) any breach by Customer of any of its
              representations or warranties in Section 8 of this Agreement; (ii) the use of the Company
              Services by Customer or any individual or entity accessing the Company Services through
              Customer’s account.
            </p>
          </div>
          <div>
            <h6>11. LIMITATION OF LIABILITY</h6>
            <p>
              <strong>11.1.</strong> Except for liability arising under section 10, to the maximum extent
              permitted by mandatorily applicable law, the Company nor their directors, employees or
              licensors, shall be liable to the Customer or any other person for any indirect, incidental,
              punitive, third party or consequential damages whatsoever, including without limitation damages
              for loss of profit or goodwill, business interruption, loss of business information or inability
              to use the Company Service, or for any matter arising out of or relating to this Agreement or
              its subject matter, whether such liability is asserted on the basis of contract, tort or
              otherwise.
            </p>
            <p>
              <strong>11.2.</strong> The Company and its licensors disclaim any liability to any person(s) or
              entities including without limitation, the Customer, for any loss or damage caused by errors or
              omissions in the information or delay or interruption in the provision of the information, save
              where such errors, omissions, delays, or interruptions result from the gross negligence or
              willful misconduct of the Company or its licensors. Furthermore, the Company disclaims any
              liability for (a) the use of the Company Services by the Customer after the termination of the
              Agreement, and (b) the use of obsolete versions of the Company Services, if the Customer is
              given access to a later version of the Company Services.
            </p>
            <p>
              <strong>11.3.</strong> In addition, except for liability arising under Section 8, in no event
              will Company’s liability under or in connection with this Agreement for any damages, losses and
              causes of actions, whether in contract or tort (including negligence or otherwise), exceed the
              amount of €100 (euros) (provided, however, that the foregoing limitation of liability shall not
              be deemed to waive any of Company’s rights at law or in equity to enforce this Agreement with
              respect to any fees or expenses due to Company by Customer under this Agreement).
            </p>
            <p>
              <strong>11.4.</strong> Some jurisdictions do not allow the exclusion or limitation of warranties
              or incidental or consequential damages, so that the above limitations or exclusions may not
              apply to the Customer. In such jurisdictions, the Company’s liability (and the liability of its
              affiliates, agents, content providers and service providers) shall be limited to the greatest
              extent permitted by applicable law.
            </p>
          </div>

          <div>
            <h6>12. MISCELLANEOUS</h6>
            <p>
              <strong>12.1. Governing Law and Jurisdiction.</strong> The validity, interpretation,
              enforceability, and performance of this Agreement shall be governed by and construed in
              accordance with the laws of Spain, without reference to its conflict of law principles All
              claims and conflicts will be submitted to the courts of the city of Barcelona, Spain, without
              regard for any other jurisdiction to which the parties may be entitled.
            </p>
            <p>
              <strong>12.2. Force Majeure.</strong> Neither party shall be liable for failure or delay in
              performing its obligations hereunder (other than an obligation to pay the Fees) if such failure
              or delay is due to circumstances beyond its reasonable control.
            </p>
            <p>
              <strong>12.3. Independent Contractors.</strong> The parties and their respective personnel are
              and shall be independent contractors and neither party by virtue of this Agreement shall have
              any right, power, or authority to act or create any obligation, express or implied, on behalf of
              the other party. This Agreement does not establish a joint venture or partnership, and the
              performance of any obligations under this Agreement will not create such relationships.
            </p>
            <p>
              <strong>12.4. Amendment.</strong> Except as otherwise provided herein, this Agreement may be
              amended by Company. Any changes will be notified to you by providing clear notice by email or
              Company’s website, and in this Terms. If you continue to use Company Services after such update,
              you will be deemed to accept the new terms. If you do not accept the update, please cancel
              notify us, and we will terminate this Agreement delete all of your personal data (except as
              required to be maintained for legal reasons), and you will no longer be able to use our
              Services.
            </p>
            <p>
              <strong>12.5. Waiver.</strong> No failure to exercise and no delay in exercising any right,
              remedy, or power hereunder shall operate as a waiver thereof, nor shall any single or partial
              exercise of any right, remedy, or power hereunder preclude any other or further exercise thereof
              or the exercise of any other right, remedy, or power provided herein or by law or in equity. The
              waiver by any party of the time for performance of any act or condition hereunder shall not
              constitute a waiver of the act or condition itself.
            </p>
            <p>
              <strong>12.6. Assignment.</strong> This Agreement shall be binding upon and inure to the benefit
              of Customer, the Company and each of their respective successors, and assigns. Customer may not
              assign or transfer this Agreement, in whole or in part, without the prior written consent of the
              Company, which consent will not be unreasonably withheld. Any attempted assignment or transfer
              in violation of the foregoing will be void.
            </p>
            <p>
              <strong>12.7. Severability.</strong> If any provision of this Agreement shall be held by a court
              of competent jurisdiction to be invalid, unenforceable, or void, the remainder of this Agreement
              shall remain in full force and effect and the stricken provision shall be replaced, to the
              extent possible, with a legal, enforceable, and valid provision that is as similar in tenor to
              the stricken provision as is legally possible.
            </p>
            <p>
              <strong>12.8. Notices.</strong> All notices to the Company hereunder shall be sent to the
              address stated above. All notices to the Customer hereunder shall be sent to the contact person
              and address as detailed in the Customer Validation Form. Any notice hereunder shall be in
              writing and shall be given by registered, certified or express mail, or reliable overnight
              courier addressed to the addresses in this Agreement, by electronic mail to the email on record
              in Customer Validation Form (providing however that confirmation is received of such email).
              Notice shall be deemed to be given upon the earlier of actual receipt or three (3) days after it
              has been sent, properly addressed and with postage prepaid.
            </p>
            <p>
              <strong>12.9. Entire Agreement.</strong> This Agreement constitute the complete and exclusive
              statement of the mutual understanding of the parties with respect to the subject matter hereof
              and supersedes all previous or contemporaneous representations, understandings, or agreements,
              oral or written, with respect to such subject matter.
            </p>
          </div>
          <div>
            <p>
              ach Party has read, understands, and agrees to the terms and conditions and to the use of
              English as the language of this Agreement, which may be incorporated in other documents or
              executed via facsimile, electronic online communications, or via emailed PDF-format document (or
              other mutually agreeable document format). The person accepting this Agreement warrants that
              he/she has authority to do so on behalf of the Customer.{' '}
            </p>
          </div>
          <h6>END OF TERMS</h6>
          <h6>VERSION: May 2024</h6>
          {/*  */}
          {/* <p className="text-left mb-3">
            <strong>Version 1.0: 05 of May 2024</strong>
          </p> */}
        </div>
        <div
          className="mb-3"
          style={{
            paddingLeft: '5.5rem',
          }}
        >
          <button
            className="btn-lg btn-secondary"
            style={{
              fontSize: '18px',
            }}
            onClick={onClose}
          >
            Close
          </button>
        </div>
        {/* <div>
          <button onClick={(onAcceptTerms, onClose)}>I accept</button>
        </div> */}
      </div>
    </div>
  );
}

export default AcademicLicensePopUp;
