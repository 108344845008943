import React from 'react';

const data = [
  {
    category: 'Genes',
    v21: 24613,
    v22: 25474,
    v23: 25869,
    v24: 25992,
    v241: 26090,
    v242: 26392,
    TotalIncrease: 302,
    percentIncrease: 1.14,
  },
  // {
  //   category: "Total genes (considering variants)",
  //   v21: 29745,
  //   v22: 31597,
  //   v23: 32052,
  //   v24: 3242,
  //   TotalIncrease: 2397,
  //   percentIncrease: 8.1,
  // },
  {
    category: 'Diseases/traits',
    v21: 36435,
    v22: 36790,
    v23: 39242,
    v24: 39644,
    v241: 39797,
    v242: 39903,
    TotalIncrease: 106,
    percentIncrease: 0.27,
  },
  {
    category: 'Variants',
    v21: 584693,
    v22: 610272,
    v23: 617724,
    v24: 616660,
    v241: 704086,
    v242: 719161,
    TotalIncrease: 15075,
    percentIncrease: 2.1,
  },
  {
    category: 'Chemicals',
    v21: 3174,
    v22: 3212,
    v23: 3507,
    v24: 3530,
    v241: 3981,
    v242: 4032,
    TotalIncrease: 51,
    percentIncrease: 1.26,
  },
  {
    category: 'GDAs',
    v21: 1509314,
    v22: 1552626,
    v23: 1729483,
    v24: 1770610,
    v241: 1793304,
    v242: 1934730,
    TotalIncrease: 141426,
    percentIncrease: 7.31,
  },
  {
    category: 'VDAs',
    v21: 1070721,
    v22: 1115471,
    v23: 1117115,
    v24: 1119130,
    v241: 1201698,
    v242: 1254874,
    TotalIncrease: 53176,
    percentIncrease: 4.24,
  },
  {
    category: 'Publications',
    v21: 1112476,
    v22: 1160823,
    v23: 1408123,
    v24: 1434206,
    v241: 1479286,
    v242: 1539056,
    TotalIncrease: 59770,
    percentIncrease: 3.88,
  },
];

function ReleasesTables() {
  return (
    <>
      <thead className="text-center">
        <tr>
          <th></th>
          {/* <th>V21</th> */}
          <th>V22</th>
          <th>V23</th>
          <th>V24</th>
          <th>V24.1</th>
          <th>V24.2</th>
          <th>Total increase (24.2 vs 24.1)</th>
          <th>% increase (24.2 vs 24.1)</th>
        </tr>
      </thead>
      <tbody className="text-center">
        {data.map((row, index) => (
          <tr key={index}>
            <td>
              <strong>{row.category}</strong>
            </td>
            {/* <td>{row.v21}</td> */}
            <td>{row.v22}</td>
            <td>{row.v23}</td>
            <td>{row.v24}</td>
            <td>{row.v241}</td>
            <td>{row.v242}</td>
            <td>{row.TotalIncrease}</td>
            <td>{row.percentIncrease}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

export default ReleasesTables;
