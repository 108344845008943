import React, { useEffect, useState, useMemo } from 'react';
import { userAppContext } from '../../Context/Context.jsx';

import ColumnSelect from './ColumnSelect.jsx';
import DownloadButtons from './DownloadButtons.jsx';
import TableColumnsMenu from './ExternalMenu/TableColumnsMenu.jsx';
import { EvidenceSentenceLegend } from './EvidenceLegend.jsx';

import RenderInfoLogic from '../InfoSidebar/RenderInfoLogic.jsx';
import Grid from '../Table/Grid.jsx';
import { Columns } from '../Table/Columns.jsx';
import { ColumnVisibilityModels } from '../Table/ColumnVisibilityModels.jsx';
import RenderSourcesLogic from './RenderSourcesLogic.jsx';

import { SourceButton } from './Buttons.jsx';

function ManageGrid({
  view,
  source,
  tab,
  searchItems,
  setSearchItems,
  isEvidence,
  isMapping,
  isInfoSidebarOpen,
  setIsInfoSidebarOpen,
}) {
  const { selectedInfoItem, mappingContent, restrictChemical } = userAppContext();
  const titleGrid = isEvidence ? 'Evidence' : 'Summary';

  // Memoized column visibility model
  const selectColumnVisibilityModel = useMemo(() => {
    const fnColumnVisibilityModel =
      isMapping && mappingContent?.isActive
        ? () => ColumnVisibilityModels.getByMappingName(mappingContent.columVisibilityModelKey, isEvidence)
        : () => ColumnVisibilityModels.getByViewTab(view, tab, isEvidence);

    const fnColumnVisibilityModelWithRestrictions = () => {
      const originalList = fnColumnVisibilityModel();
      const restrictedList = restrictChemical
        ? ['gof_lof', 'chemsInSentence', 'chemUMLSCUI', 'chemUMLSCUIs', 'chemical_effect']
        : [];
      return originalList && restrictedList
        ? Object.fromEntries(Object.entries(originalList).filter(([key]) => !restrictedList.includes(key)))
        : undefined;
    };
    return fnColumnVisibilityModelWithRestrictions;
  }, [view, tab, mappingContent?.columVisibilityModelKey, restrictChemical, isMapping, isEvidence]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(selectColumnVisibilityModel());

  useEffect(() => {
    setColumnVisibilityModel(selectColumnVisibilityModel());
  }, [selectColumnVisibilityModel]);

  // Memoized columns
  const columns = useMemo(() => {
    return columnVisibilityModel
      ? Object.keys(columnVisibilityModel).flatMap(key =>
          Columns.filter(column => key === column.name && columnVisibilityModel[column.name] === true),
        )
      : null;
  }, [columnVisibilityModel]);

  const [totalResults, setTotalResults] = useState(0);
  const [isColumnSelectOpen, setIsColumnSelectOpen] = useState(false);
  const [sourceOpen, setSourceOpen] = useState(false);

  useEffect(() => {
    const searchAndMappingItems = searchItems ? searchItems.concat(mappingContent?.mappingItems) : [];
    if (isInfoSidebarOpen && searchItems && !searchItems.find(item => item.id === selectedInfoItem.id)) {
      if (!searchAndMappingItems.find(item => item.id === selectedInfoItem.id)) {
        setIsInfoSidebarOpen(false);
      }
    }
  }, [searchItems, selectedInfoItem, mappingContent?.mappingItems, isInfoSidebarOpen, setIsInfoSidebarOpen]);

  return !columns ? (
    ''
  ) : (
    <>
      <div className="Cabtabla  bg-white mt-3">
        {searchItems && (
          <>
            {' '}
            <div className="row">
              <RenderInfoLogic
                searchItems={searchItems}
                setSearchItems={setSearchItems}
                setIsInfoSidebarOpen={setIsInfoSidebarOpen}
                source={source}
                isInfoSidebarOpen={isColumnSelectOpen}
                isMapping={isMapping}
              />
              <div className="col-4 text-right pr-3">
                <div className="justify-content-end">
                  <ColumnSelect setIsColumnSelectOpen={setIsColumnSelectOpen} />
                  <SourceButton titleGrid={titleGrid} source={source} setSourceOpen={setSourceOpen} />
                </div>
                <div className="justify-content-end">
                  {view !== 'ALL' && (
                    <DownloadButtons
                      searchMethod={'MENU'}
                      totalResults={totalResults}
                      columnVisibilityModel={columnVisibilityModel}
                      titleGrid={titleGrid}
                      columns={columns}
                      // setFileExtension={setFileExtension}
                      // setShowConfirmationModal={setShowConfirmationModal}
                      // downloadData={downloadData}
                    />
                  )}
                </div>
              </div>
              <div>{sourceOpen && <RenderSourcesLogic tab={tab} />}</div>
            </div>
            {isColumnSelectOpen && (
              <TableColumnsMenu
                view={view}
                tab={tab}
                setIsColumnSelectOpen={setIsColumnSelectOpen}
                columnVisibilityModel={columnVisibilityModel}
                setColumnVisibilityModel={setColumnVisibilityModel}
              />
            )}
            <div className="titEtiqueta">
              <span>{titleGrid}</span>
            </div>
          </>
        )}
      </div>

      {isEvidence && <EvidenceSentenceLegend />}

      <Grid
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        view={view}
        source={source}
        tab={tab}
        searchItems={searchItems}
        isEvidence={isEvidence}
        isMapping={isMapping}
        setTotalResults={setTotalResults}
      />
    </>
  );
}
export default ManageGrid;
