import { Amplify } from 'aws-amplify';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { gAnalytics } from './js/Constants/Constants';

import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';

import { Auth } from 'aws-amplify';
import ScrollToTop from './js/Components/ScrollToTop/ScrollToTop';
import { patchUseSearchItems } from './js/Components/Patches/PatchSearchItems';
import { AppContext } from './js/Context/Context';
import { CustomRoutes } from './js/Routes/AppRouter';
import getApi from './js/Services/GetApi';
import * as Sentry from '@sentry/react';

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

// Configure Google Analytics
const gaTrackingId = gAnalytics.googleAnalyticsTrackingId;
ReactGA.initialize(gaTrackingId);

// Configure Sentry
if (process.env.NODE_ENV !== 'production') {
  Sentry.init({
    dsn: 'https://aba19d681a08769aed34511cdaf4a06b@o4506586512228352.ingest.us.sentry.io/4506586513997824',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // 'localhost',
      // /^https:\/\/disgenet\.com/,
      /^https:\/\/test\.medbioinformatics\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Suppress known warnings
const error = console.error;
console.error = (...args) => {
  // https://github.com/recharts/recharts/issues/3615
  if (/defaultProps/.test(args[0])) return;
  //error(...args);
  Sentry.captureMessage(args, 'error');
};

export const App = () => {
  // auth state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authError, setAuthError] = useState('');
  const [authorization, setAuthorization] = useState('');
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [userApiKey, setUserApiKey] = useState([]);
  const [resultados, setResultados] = useState([]);
  const [names, setNames] = useState([]);
  const [namesBuscador, setNamesBuscador] = useState([]);
  const [load, setLoad] = useState(false);
  const [lista, setLista] = useState([]);

  // nav state
  const [view, setView] = useState('');
  const [idents, setIdents] = useState(''); // todo: finish deleting from app, not used
  const [source, setSource] = useState('');
  const [tab, setTab] = useState('');
  // const [searchItems, setSearchItems] = useState([]);
  const [searchItems, setSearchItems] = patchUseSearchItems();
  const [hasValidParams, setHasValidParams] = useState(false);

  // Info sidebar state
  const [selectedInfoItem, setSelectedInfoItem] = useState(null);
  const [geneContent, setGeneContent] = useState({
    isActive: false,
    geneInfo: { symbol: '', id: null, name: '' },
  });

  // Mappings state
  const [mappingContent, setMappingContent] = useState({
    isActive: false,
  });

  // user restrictions
  const [restrictGridDisplay, setRestrictGridDisplay] = useState(true);
  const [restrictGridResultCount, setRestrictGridResultCount] = useState(true);
  const [restrictGraphDisplay, setRestrictGraphDisplay] = useState(true);
  const [restrictSources, setRestrictSources] = useState(false); // todo: why is this the only one who's default state is to not restrict?
  // const [restrictDownloadLimit, setRestrictDownloadLimit] = useState(30); // implemented in backend
  const [restrictChemical, setRestrictChemical] = useState(true);
  const [restrictPathwayDisplay, setRestrictPathwayDisplay] = useState(true);
  const [restrictSort, setRestrictSort] = useState(true);
  const [restrictSortOrder, setRestrictSortOrder] = useState(true);

  $('.popover-header').hide();

  function onLoad() {
    // auth
    try {
      Auth.currentSession()
        .then(user => {
          setAuthorization(user.idToken.jwtToken);
          setGroups(
            user.idToken.payload['cognito:groups'] == undefined ? [] : user.idToken.payload['cognito:groups'],
          );
          setUser(user.idToken.payload);
          setUserApiKey('');

          if (
            user !== '' &&
            user.idToken.payload['custom:created_at'] !== undefined &&
            user.idToken.payload['cognito:groups'].includes('ROLE_TRIAL')
          ) {
            const transformDaysToMs = 24 * 60 * 60 * 1000;
            let trialTime = 7 * transformDaysToMs; // 7 days 604800000
            //let trialTime = 2*60*1000;
            let created_at =
              Number.parseInt(user.idToken.payload['custom:created_at']) + Number.parseInt(trialTime);
            const extendedTrialTime = user.idToken.payload['custom:extended_trial_days']
              ? Number.parseInt(user.idToken.payload['custom:extended_trial_days']) * transformDaysToMs
              : 0;

            const expiryTime = created_at + extendedTrialTime;

            let today = new Date().getTime();
            //console.log ('login today ' + today);
            //console.log ('app created_at ' + created_at);
            if (expiryTime < today) {
              //console.log ('Se ha caducado la prueba gratis de 7 días.');
              // todo: error message when trial has expired
              setIsAuthenticated(false);
            } else {
              setIsAuthenticated(true);
              getUserAPIKey(user.idToken.payload['cognito:username']);
            }
          } else {
            setIsAuthenticated(true);
            getUserAPIKey(user.idToken.payload['cognito:username']);
          }
        })
        .catch(error => {
          if (error === 'No current user') {
            // Before logging in, no current user will exist
            setIsAuthenticated(false);
          } else {
            console.error('Error during authentication', error);
          }
        });
    } catch (e) {
      console.error('error outside promise,', e);
    }

    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    // restrict grid display
    setRestrictGridDisplay(!isAuthenticated);
    // restrict grid result count
    if (!isAuthenticated || !groups) {
      setRestrictGridResultCount(10);
    } else if (groups?.includes('ROLE_TRIAL')) {
      setRestrictGridResultCount(30);
    } else {
      setRestrictGridResultCount(false);
    }
    // restrict graph display
    setRestrictGraphDisplay(!isAuthenticated || groups.includes('ROLE_ACADEMIC'));
    // restrict sources
    setRestrictSources(groups.includes('ROLE_ACADEMIC') ? 'CURATED' : false);
    // restrict pathways
    setRestrictPathwayDisplay(!isAuthenticated || groups.includes('ROLE_ACADEMIC'));
    // restrict chemical data access
    setRestrictChemical(!isAuthenticated || groups.includes('ROLE_ACADEMIC'));
    // restrict sorting
    setRestrictSort(!isAuthenticated || groups.includes('ROLE_ACADEMIC')); // todo: correct?
    // restrict sort order
    setRestrictSortOrder(!isAuthenticated || groups.includes('ROLE_ACADEMIC')); // todo: correct?
  }, [isAuthenticated, groups]);

  function getUserAPIKey(userId) {
    //console.log ('getUserAPIKey ' + JSON.stringify (userId));

    getApi(userId)
      .then(datos => {
        if (datos && datos.status === 'OK') {
          setUserApiKey(datos.payload.api_key);
        } else {
          console.error('API call error or invalid data.');
        }
      })
      .catch(error => {
        console.error('API call error:', error);
      });
  }

  return (
    <React.Fragment>
      <AppContext.Provider
        value={{
          isAuthenticated,
          setIsAuthenticated,
          authorization,
          setAuthorization,
          groups,
          setGroups,
          userApiKey,
          setUserApiKey,
          authError,
          setAuthError,
          user,
          setUser,
          view,
          setView,
          idents,
          setIdents,
          source,
          setSource,
          tab,
          setTab,
          searchItems,
          setSearchItems,
          selectedInfoItem,
          setSelectedInfoItem,
          hasValidParams,
          setHasValidParams,
          geneContent,
          setGeneContent,
          mappingContent,
          setMappingContent,
          restrictGridDisplay,
          restrictGridResultCount,
          restrictGraphDisplay,
          restrictSources,
          restrictChemical,
          restrictPathwayDisplay,
        }}
      >
        <CustomRoutes />
        <ScrollToTop />
      </AppContext.Provider>
    </React.Fragment>
  );
};

// handle Cloudfront redirects before app mount
if (window.location.hash.includes('#!')) {
  const newPath = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  history.replaceState(history.state, '', newPath);
}

const root = document.getElementById('root');
createRoot(root).render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
);

reportWebVitals();
