import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import Nav from '../Components/FAQ/Nav';
import FAQContent from '../Components/FAQ/FAQContent';
import Footer from '../Components/Home/Footer';
import $ from 'jquery';

export default function AboutFaq() {
  $('.popover').hide();
  ReactGA.send({ hitType: 'pageview', page: '/FAQ', title: 'FAQ' });

  return (
    <React.Fragment>
      <CommonHeader />
      <FAQContent />
      <Footer />
    </React.Fragment>
  );
}
