import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeatureHome from './FeatureHome';
import Feature2Home from './Feature2Home';
import Feature3Home from './Feature3Home';
import Testimony from '../SignUp/Testimony';

function Container2() {
  const [showChallenge, setShowChallenge] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);

  const toggleSection = section => {
    if (section === 'challenge') {
      setShowChallenge(!showChallenge);
    } else if (section === 'solution') {
      setShowSolution(!showSolution);
    } else if (section === 'benefits') {
      setShowBenefits(!showBenefits);
    }
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/free-trial-apply');
  };
  const handleClick2 = () => {
    navigate('/plans');
  };

  return (
    <section>
      {/* <div className="customers" style={{ marginBottom: '50px' }}>
        <h3 className="p-2">logos</h3>
      </div> */}
      <div className="p-2">
        <div>
          <h3 className="custom-home-h3 gradient-color">
            Accessing reliable and up-to-date biomedical data is becoming more and more challenging
          </h3>
          <h6>
            Many databases exist and scientific literature keeps growing, but this doesn't solve the problem
            of data access
          </h6>
        </div>
      </div>
      <div>
        <div className="pb-4">
          <FeatureHome />
        </div>
        <div className="pb-4">
          <Feature2Home />
        </div>
        <div className="p-2 text-center">
          <button className="btn-gradient-pink" onClick={handleClick}>
            START A FREE TRIAL
          </button>
        </div>
        <br />
        <br />
        <h3 className="pb-4 custom-home-h3 gradient-color">
          What do customers and researchers say about DISGENET?
        </h3>
        <Testimony />

        <div className="p-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button className="btn-gradient-pink" onClick={handleClick}>
            START A FREE TRIAL
          </button>
          <button className="btn-gradient-blue" onClick={handleClick2}>
            VIEW PLANS
          </button>
        </div>
        <br />
        <br />
        <Feature3Home />
      </div>
    </section>
  );
}

export default Container2;
