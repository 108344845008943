import { getFiltersMap } from '../ReactDataGrid/SortFilterTables';

export default function buildResultEndpoint({
  view,
  source,
  tab,
  searchItems,
  isEvidence = null,
  isMapping = null,
  mappingContent = null,
  pageNum = 0,
  pageSize,
  orderByParam = null,
  order = null,
  defaultFilterValue = null,
}) {
  // view mappings
  let viewSegment;
  viewSegment = view === 'ALL' ? '/all' : '/summary';
  viewSegment = ['VDA', 'GDA'].includes(tab) ? '/summary' : viewSegment; // VDA ALL and GDA ALL
  viewSegment = tab === 'DDA' ? '' : viewSegment; // DDA has its own backend pattern
  viewSegment = isEvidence === true ? '' : viewSegment; // Evidence grids

  // idents to filter mappings
  // The param name:
  let filterParam = null;
  filterParam = view === 'GENES' ? 'gene_ncbi_id' : filterParam;
  filterParam = view === 'DISEASES' ? 'disease' : filterParam;
  filterParam = view === 'VARIANTS' ? 'variant' : filterParam;
  filterParam = view === 'CHEMICALS' ? 'chem_cuis_init' : filterParam;
  filterParam = viewSegment === '/all' ? null : filterParam; // no idents for /all views

  // The backend idents value:
  let backendDiseaseVocabPrefix = viewSegment !== '/all' && filterParam === 'disease' ? 'UMLS_' : ''; // disease idents need to be patched for association views
  let filterValue = searchItems.map(item => backendDiseaseVocabPrefix + item.id).join(',');

  // tab mappings
  let tabSegment = tab?.toLowerCase().replace(/s$/, '');
  tabSegment = tabSegment === 'chemical' ? 'chem' : tabSegment;

  // order mapping
  order = order === 1 ? 'asc' : order === -1 ? 'desc' : order;

  // Update orderByParam with custom mappings because of inconsistencies with backend schema
  // _source tagged order fields:
  const specialSortParams = [
    'numGenesAssociatedToDisease',
    'numVariantsAssociatedToDisease',
    'numVariantsAssociatedToGene',
    'numDiseasesAssociatedToGene',
    'numDiseasesAssociatedToVariant',
    'numPMIDsupportingAssociation',
    'numDBSNPsupportingAssociation',
    'numPublications',
  ];
  if (specialSortParams.includes(orderByParam)) {
    orderByParam = `${orderByParam}_${source}`;
  }
  if (['yearInitial', 'yearFinal'].includes(orderByParam) && tab === 'GDA') {
    orderByParam = orderByParam + '_' + source;
  }
  // Handle specific naming inconsistencies or mappings
  switch (orderByParam) {
    case 'chemical_effect':
      orderByParam = 'tox_ther'; //'tox_ther_biomednlp'
      break;
    case 'diseaseClassName':
      orderByParam = orderByParam.replace('_', '');
      break;
    case 'ddaRelation':
      orderByParam = 'rela';
      break;
    case 'symbol':
      orderByParam = 'symbol_keyword';
      break;
    case 'chemsInSentence':
      orderByParam = 'chemShowName';
      break;
    default:
      break;
  }

  // // Build params
  // Base
  const paramsMap = {
    source: source,
    // ...(filterParam && { [filterParam]: filterValue }),
    page_number: pageNum,
    page_size: pageSize,
    order_by: orderByParam,
    order: order,
  };

  // Apply user selected filters
  if (isMapping) {
    // override url after api/
    // *only if provided, else pass through on current url
    tabSegment = mappingContent.tabSegment ?? tabSegment;
    viewSegment = mappingContent.viewSegment === '/all' ? '/all' : '/summary';
    viewSegment = isEvidence === true ? '' : viewSegment; // Evidence grids
    viewSegment = tabSegment.includes('shared') ? '' : viewSegment; // sharing endpoint doesn't use /summary
    if (isEvidence) {
      viewSegment = '';
    } else if (mappingContent.viewSegment == '/all') {
      viewSegment = mappingContent.viewSegment;
    }
    // Apply user selected filters
    if (mappingContent.mappingItems) {
      mappingContent.mappingItems.forEach(item => {
        backendDiseaseVocabPrefix = viewSegment !== '/all' && item.filterParam === 'disease' ? 'UMLS_' : ''; // disease idents need to be patched for association views
        // backendDiseaseVocabPrefix = tabSegment.includes['chem/gda'] ? 'UMLS_' : ''; // this endpoint doesn't use prefix
        // todo: do I need backendDiseaseVocabPrefix?
        // Not needed for chem/gda/all, true for all 8?
        // vda, needed, yes
        // Comma join values if value already exists:
        if (paramsMap[item.filterParam]) {
          paramsMap[item.filterParam] += ',' + backendDiseaseVocabPrefix + item.filterValue;
        } else {
          paramsMap[item.filterParam] = backendDiseaseVocabPrefix + item.filterValue;
        }
      });
    } else {
      console.error('No mappingItems provided for mappingContent');
      return null;
    }
  } else {
    // apply url context based filter
    paramsMap[filterParam] = filterValue;
  }

  // Apply string filters from ReactDataGrid
  defaultFilterValue.forEach(filter => {
    if (filter.value && ['string', 'select'].includes(filter.type)) {
      const map = getFiltersMap().get(filter.name) ?? filter.name;
      paramsMap[map] = filter.value;
    }
  });

  // Apply number filters from ReactDataGrid
  defaultFilterValue.forEach(filter => {
    if (filter.value && filter.type === 'number') {
      const { name, value, operator } = filter;
      const map = getFiltersMap().get(name) ?? filter.name;
      paramsMap[map] = value;

      let baseValue;
      let offsetNegative, offsetPositive;
      let floatBuffer;
      if (filter?.integer === true) {
        baseValue = parseInt(value);
        offsetNegative = parseInt(baseValue) - 1;
        offsetPositive = parseInt(baseValue) + 1;
        floatBuffer = 0;
      } else {
        baseValue = parseFloat(value);
        floatBuffer = 0.005;
        offsetNegative = (baseValue - floatBuffer).toFixed(4);
        offsetPositive = (baseValue + floatBuffer).toFixed(4);
      }

      switch (operator) {
        case 'eq':
          paramsMap[`min_${map}`] = baseValue - floatBuffer;
          paramsMap[`max_${map}`] = baseValue + floatBuffer;
          break;
        case 'gt':
          paramsMap[`min_${map}`] = offsetPositive;
          break;
        case 'gte':
          paramsMap[`min_${map}`] = baseValue;
          break;
        case 'lt':
          paramsMap[`max_${map}`] = offsetNegative;
          break;
        case 'lte':
          paramsMap[`max_${map}`] = baseValue;
          break;
      }
    }
  });

  // Construct URL params
  // clean up any nulls that snuck in
  const params = new URLSearchParams(
    // Object.entries(paramsMap).filter(([param, value]) => param !== null || value !== null),
    Object.entries(paramsMap)
      .filter(([param, value]) => value !== null)
      .filter(([param, value]) => value !== '')
      .filter(([param, value]) => param !== 'null'),
  );

  // Construct endpoint URL
  const baseEndpoint = `api/${tabSegment}${viewSegment}`;
  let endPoint = `${baseEndpoint}?${params.toString()}`;

  return endPoint;
}
