import React, { useState, useEffect } from 'react';
import UseInput from '../../Hooks/UseInput';
import { validateEmail, validateNotEmpty } from '../Contact/ValidationLogic';
import PrivacyPopUp from './PrivacyPolicyPopUp';
import AcademicLicensePopUp from './AcademicLicensePopUp';
import VerifyEmailPopup from './VerifyEmailPopUp';
import { Auth } from 'aws-amplify';

function ConfirmationPopup({ onClose, onConfirm }) {
  return (
    <div className="RegisterPopup">
      <div className="apply-popup-container">
        <h5>
          Your application will be manually reviewed. Please double-check all information is complete &
          accurate. Incomplete or inaccurate information may result in rejection. What would you like to do?
        </h5>
        <button onClick={onClose} className="button-home">
          Review & Edit
        </button>
        <button onClick={onConfirm} className="button-home" aria-label="submit-modal">
          Confirm & Submit
        </button>
      </div>
    </div>
  );
}

function SignUpForm() {
  const isAcademic = location.pathname === '/academic-apply';

  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = useState(false);
  const [academicLicenseAccepted, setAcademicLicenseAccepted] = useState(false);
  const [showAcademicLicensePopup, setShowAcademicLicensePopup] = useState(false);

  const [password, setPassword] = useState('');
  const [rpassword, setRpassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isOrganizationValid, setIsOrganizationValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatching, setIsPasswordMatching] = useState(true);
  const [isRpasswordValid, setIsRpasswordValid] = useState(true);
  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  //remove autocomplete and keep css
  useEffect(() => {
    const disableAutoComplete = input => {
      input.setAttribute('readonly', 'readonly');
      setTimeout(() => {
        input.removeAttribute('readonly');
      }, 100);
    };
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => disableAutoComplete(input));
  }, []);

  function resetForm() {
    setName('');
    setEmail('');
    setOrganization('');
    setLinkedin('');
    setPrivacyPolicyAccepted(false);
    setAcademicLicenseAccepted(false);
    setPassword('');
    setRpassword('');
    setIsPasswordVisible(false);
    setFormErrorMessage(null);
    setIsFormSubmitted(false);
  }

  function validateForm() {
    // checkbox validations
    if (!privacyPolicyAccepted) {
      setFormErrorMessage('You must accept the privacy policy before submitting.');
      return false;
    }
    if (isAcademic && !academicLicenseAccepted) {
      setFormErrorMessage('You must accept the license agreement before submitting.');
      return false;
    }
    // check text input validations
    const textValidations = [
      validateNameInput(name),
      validateEmailInput(email),
      validateOrganizationInput(organization),
      validatePasswordsInput(password, rpassword),
    ];
    if (!textValidations.every(bool => bool === true)) {
      setFormErrorMessage('Check text inputs for errors');
      return false;
    }
    // Passed all validations, return true
    setFormErrorMessage('');
    return true;
  }

  // Name input
  const handleNameChange = evt => {
    setName(evt.target.value);
  };

  const handleNameBlur = evt => {
    validateNameInput(evt.target.value);
  };

  const nameRegex = /^[a-zA-Zà-úÀ-Ú'´` -]+$/;

  function validateNameInput(value) {
    const isValid = validateNotEmpty(value) && nameRegex.test(value);
    setIsNameValid(isValid);
    return isValid;
  }

  // Email input
  const handleEmailChange = evt => {
    setEmail(evt.target.value?.trimEnd());
  };

  const handleEmailBlur = evt => {
    const isValid = validateEmailInput(evt.target.value);
  };

  function validateEmailInput(value) {
    const isValid = validateEmail(value, isAcademic);
    setIsEmailValid(isValid);
    return isValid;
  }

  // organization input
  const handleOrganizationChange = evt => {
    setOrganization(evt.target.value);
  };

  const handleOrganizationBlur = evt => {
    validateOrganizationInput(evt.target.value);
  };

  function validateOrganizationInput(value) {
    const isValid = validateNotEmpty(value);
    setIsOrganizationValid(isValid);
    return isValid;
  }

  // linkedin input
  const handlesupportingLinksChange = evt => {
    setLinkedin(evt.target.value);
  };

  // checkboxes
  const handleAcademicLicenseCheckboxChange = evt => {
    setAcademicLicenseAccepted(evt.target.checked);
  };

  const handleAcademicLicensePopup = () => {
    setShowAcademicLicensePopup(true);
  };

  const handlePrivacyPolicyCheckboxChange = evt => {
    setPrivacyPolicyAccepted(evt.target.checked);
  };

  const handlePrivacyPolicyPopup = () => {
    setShowPrivacyPolicyPopup(true);
  };

  // password/rpassword input
  const handlePasswordChange = evt => {
    setPassword(evt.target.value);
  };

  const handlePasswordBlur = evt => {
    validatePasswordsInput(evt.target.value, rpassword);
  };

  const handleRpasswordChange = evt => {
    setRpassword(evt.target.value);
  };

  const handleRpasswordBlur = evt => {
    validatePasswordsInput(password, evt.target.value);
    // check rpassword for empty
    const isValidR = validateNotEmpty(rpassword);
    setIsRpasswordValid(isValidR);
  };

  function validatePasswordsInput(password, rpassword) {
    // check password for empty
    const isValid = validateNotEmpty(password);
    setIsPasswordValid(isValid);

    // check if passwords match
    let doPasswordsMatch;
    if (password && rpassword) {
      doPasswordsMatch = password === rpassword;
      setIsPasswordMatching(doPasswordsMatch);
    }
    return isValid && doPasswordsMatch;
  }

  const handleOpenConfirmationPopup = () => {
    if (validateForm()) {
      setShowConfirmationPopup(true);
    }
  };

  async function handleSubmit(evt) {
    evt.preventDefault();

    const created_at = new Date().getTime().toString();

    const payload = {
      username: email,
      password: password,
      attributes: {
        email,
        'custom:company': organization,
        'custom:name': name,
        'custom:created_at': created_at,
        'custom:linkedin': linkedin,
        'custom:application_role': isAcademic ? 'ROLE_ACADEMIC' : 'ROLE_TRIAL',
      },
    };

    if (validateForm()) {
      setFormErrorMessage('Sending...');
      try {
        const response = await Promise.resolve(Auth.signUp(payload))
          .then(result => {
            resetForm();
            setShowVerifyEmailPopup(true);
            setIsFormSubmitted(true);
            setShowConfirmationPopup(false);
          })
          .catch(ex => {
            setFormErrorMessage(ex.message);
            setFormErrorMessage(ex.message);
          });
      } catch (e) {
        setFormErrorMessage(
          'There was an error with application submission. Please try again or contact us by email at info@disgenet.com',
        );
      }
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className="form-apply">
          <form onSubmit={handleSubmit}>
            <h4 className="apply-title">{isAcademic ? 'Academic Apply' : 'Start A Free Trial'}</h4>
            {isFormSubmitted && (
              <div className="form-success">
                <p className="form-success">Check your inbox within 24hrs to complete your application</p>
              </div>
            )}
            <span className="label-input">Full Name *</span>
            <div className="line-input" data-validate="Name is required">
              <input
                className={`form-input ${!isNameValid ? 'invalid-input' : ''}`}
                aria-label="name"
                type="text"
                placeholder="Full Name..."
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                value={name}
              />
            </div>
            <div>{!isNameValid && <p className="apply-input-error">Please enter a valid name</p>}</div>
            <span className={`label-input`}>Email *</span>
            <div className="line-input" data-validate="Valid email is required: ex@abc.xyz">
              <input
                className="form-input"
                aria-label="email"
                type="text"
                placeholder="Email address..."
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                value={email}
              />
            </div>
            <div>
              {!isEmailValid && (
                <p className="apply-input-error ">
                  {isAcademic
                    ? 'Please enter a valid institutional email'
                    : 'Only academic or business emails are accepted'}
                </p>
              )}
            </div>
            <span className={`label-input`}>Organization *</span>
            <div className="line-input" data-validate="Name is required">
              <input
                className="form-input"
                aria-label="organization"
                type="text"
                placeholder={isAcademic ? 'University, research institute...' : 'Company...'}
                onChange={handleOrganizationChange}
                onBlur={handleOrganizationBlur}
                value={organization}
              />
            </div>
            <div>
              {!isOrganizationValid && (
                <p className="apply-input-error">Please enter your organization's name</p>
              )}
            </div>
            <span className={`label-input`}>Supporting Links</span>
            <div className="line-input">
              <input
                className="form-input"
                aria-label="linkedin"
                type="text"
                placeholder="linkedin, personal web, etc..."
                onChange={handlesupportingLinksChange}
                value={linkedin}
              />
            </div>
            <span className={`label-input`}>Create Password *</span>{' '}
            <button
              type="button"
              className="eye-icon"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              <i className={isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
            </button>
            <div className="line-input" data-validate="Name is required">
              <input
                className="form-input"
                aria-label="password"
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder=""
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                value={password}
              />
            </div>
            <div>{!isPasswordValid && <p className="apply-input-error">Please enter a password</p>}</div>
            <span className="label-input">Retype Password *</span>
            <button
              type="button"
              className="eye-icon"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              <i className={isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
            </button>
            <div className="line-input" data-validate="Name is required">
              <input
                className="form-input"
                aria-label="rpassword"
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder=""
                onChange={handleRpasswordChange}
                onBlur={handleRpasswordBlur}
                value={rpassword}
              />
            </div>
            {isAcademic && (
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="academicLicenseCheckbox"
                  checked={academicLicenseAccepted}
                  onChange={handleAcademicLicenseCheckboxChange}
                />
                <label className="">
                  I accept the{' '}
                  <button
                    type="button"
                    onClick={handleAcademicLicensePopup}
                    style={{ borderBottom: '2px solid rgb(192,195,202)' }}
                  >
                    DISGENET academic License
                  </button>
                </label>
              </div>
            )}
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                aria-label="privacyPolicyCheckbox"
                type="checkbox"
                name="privacyPolicyCheckbox"
                checked={privacyPolicyAccepted}
                onChange={handlePrivacyPolicyCheckboxChange}
              />
              <label className="">
                I have read and accept{' '}
                <button
                  type="button"
                  onClick={handlePrivacyPolicyPopup}
                  style={{ borderBottom: '2px solid rgb(192,195,202)' }}
                >
                  DISGENET's privacy policy
                </button>
              </label>
            </div>
            <hr></hr>
            <div>
              {!isRpasswordValid && <p className="apply-input-error">Please re-type your password</p>}
            </div>
            <div>{!isPasswordMatching && <p className="apply-input-error">Passwords must match</p>}</div>
            <div>{formErrorMessage && <p className="form-error">{formErrorMessage}</p>}</div>
            {isFormSubmitted && (
              <div className="form-success">
                <p className="form-success">Check your inbox within 24hrs to complete your application</p>
              </div>
            )}
            <div className="div-apply-btn">
              <button
                type="button"
                aria-label="submit"
                className="apply-form-btn"
                onClick={handleOpenConfirmationPopup}
              >
                {isAcademic ? 'Apply Free Now' : 'Start Free Trial'}
              </button>
            </div>
          </form>
        </div>
      </section>
      {showAcademicLicensePopup && (
        <AcademicLicensePopUp
          onClose={() => setShowAcademicLicensePopup(false)}
          onAcceptTerms={() => setAcademicLicenseAccepted(true)}
        />
      )}
      {showPrivacyPolicyPopup && (
        <PrivacyPopUp
          onClose={() => setShowPrivacyPolicyPopup(false)}
          onAcceptTerms={() => setPrivacyPolicyAccepted(true)}
        />
      )}
      {showConfirmationPopup && (
        <ConfirmationPopup onClose={() => setShowConfirmationPopup(false)} onConfirm={handleSubmit} />
      )}
    </React.Fragment>
  );
}

export default SignUpForm;
