import { useNavigate } from 'react-router-dom';

function Feature3Home() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/plans');
  };

  return (
    <section>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <h3 className="pb-2 custom-home-h3 gradient-color">Dedicated to supporting academic research</h3>
          <h6 className="pb-2">
            Are you a student, academic or not-for-profit researcher who wants to access the latest biomedical
            data?
          </h6>
          <h6>Unlock the full potential of your research with DISGENET's dedicated academic plans.</h6>
          <div className="box-home-div"></div>
          <button className="btn-gradient-blue" onClick={handleClick}>
            VIEW PLANS
          </button>
        </div>
        <div>
          <img src="../../../static/images/dis-Institutions.png" />
          <h6 style={{ color: 'rgb(154,159,170)' }}>
            DISGENET is built by researchers from academic institutions
          </h6>
        </div>
      </div>
    </section>
  );
}

export default Feature3Home;
