const CountryCodes = [
  'it',
  'uk',
  'us',
  'ca',
  'cat',
  'au',
  'nz',
  'jp',
  'kr',
  'cl',
  'cn',
  'br',
  'mx',
  'ru',
  'de',
  'fr',
  'es',
  'nl',
  'se',
  'no',
  'fi',
  'dk',
  'ch',
  'at',
  'be',
  'pl',
  'cz',
  'hu',
  'ro',
  'gr',
  'pt',
  'tr',
  'il',
  'sa',
  'ae',
  'sg',
  'mt',
  'my',
  'id',
  'ph',
  'th',
  'vn',
  'in',
  'pk',
  'lk',
  'bd',
  'np',
  'za',
  'eg',
  'ma',
  'dz',
  'tn',
  'ke',
  'ug',
  'gh',
  'ng',
  'et',
  'ci',
  'cm',
  'sn',
  'tz',
  'zm',
  'zw',
  'mw',
  'ug',
  'rw',
  'mu',
  'mg',
  'sc',
  'so',
  'sd',
  'ly',
  'jo',
  'lb',
  'iq',
  'sy',
  'ye',
  'om',
  'kw',
  'bh',
  'qa',
  'ae',
  'ir',
  'af',
  'kz',
  'uz',
  'tm',
  'kg',
  'tj',
  'mn',
  'np',
  'bt',
  'mm',
  'la',
  'kh',
  'vn',
  'th',
  'my',
  'sg',
  'ph',
  'id',
  'tl',
  'pg',
  'sb',
  'fj',
  'vu',
  'nz',
  'au',
  'to',
  'ws',
  'tv',
  'ki',
  'nr',
  'pw',
  'fm',
  'mh',
  'pw',
  'nr',
  'ki',
  'tv',
  'ws',
  'to',
  'vu',
  'fj',
  'sb',
  'pg',
  'tl',
  'id',
  'ph',
  'sg',
  'my',
  'th',
  'vn',
  'kh',
  'la',
  'mm',
  'bt',
  'np',
  'lk',
];
export default CountryCodes;
