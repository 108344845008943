const isViewValid = view => {
  const possibleViews = ['DISEASES', 'GENES', 'VARIANTS', 'CHEMICALS', 'ALL'];
  return possibleViews.includes(view);
};

const isIdentsValid = idents => {
  // todo: any idents validation?
  return idents.length >= 1 ? true : false;
};

const isSourceValid = source => {
  const possibleSources = [
    'ALL',
    'CLINGEN',
    'CLINVAR',
    'CURATED',
    'GWASCAT',
    'HPO',
    'INFERRED',
    'MGD_HUMAN',
    'MGD_MOUSE',
    'MODELS',
    'ORPHANET',
    'PHEWASCAT',
    'PSYGENET',
    'RGD_HUMAN',
    'RGD_RAT',
    'TEXTMINING_HUMAN',
    'TEXTMINING_MODELS',
    'UNIPROT',
    //'CLINICALTRIALS',
  ];

  return possibleSources.includes(source);
};

const isTabValid = (view, tab) => {
  const possibleTabs = {
    DISEASES: ['GDA', 'VDA', 'DDA'],
    GENES: ['GDA', 'VDA'],
    CHEMICALS: ['GDA', 'VDA'],
    VARIANTS: ['VDA'],
    ALL: ['GDA', 'VDA', 'DISEASES', 'GENES', 'VARIANTS', 'CHEMICALS'],
  };

  return possibleTabs[view].includes(tab);
};

export const validateQueryParams = searchParams => {
  const view = searchParams.get('view');
  const idents = searchParams.get('idents');
  const source = searchParams.get('source');
  const tab = searchParams.get('tab');

  // return false if any params are empty
  if (!view || !idents || !source || !tab) {
    return false;
  }

  // if one of the conditions above isn't valid, the whole URL is invalid
  return isViewValid(view) && isIdentsValid(idents) && isSourceValid(source) && isTabValid(view, tab);
};
