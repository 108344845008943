import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Tags from './Tags';
import Dropdown from './Dropdown';
import TextInput from './TextInput';
import { FirstSearchButton } from './SubmitButton';
import { set } from 'lodash';
import { searchContext, userAppContext } from '../../Context/Context';
import Axios from '../../Services/Axios';
import { API_URL } from '../Constants';
import Tag from './Tag';
import { use } from 'cytoscape';

const SearchBar = () => {
  const { view, source, setSource, tab, setTab, searchItems, setSearchItems } = searchContext();
  const [value, setValue] = useState(''); // text input in search box

  useEffect(() => {
    // Clear searchbox on navigation changes
    setValue('');
  }, [location.pathname, location.search]);

  // Legacy stuff:
  // used for some kind of DOM state mgmt:
  // if (!resultados.includes(name)) {
  //   //   setLista(prevState => [...prevState, value]);
  //   //   setResultados(prevState => [...prevState, name]);
  //   //   setNames(prevState => [...prevState, placeholder]);
  //   //   if (ident == '' || ident == DATASETS.ALL) {
  //   //     setIdent(id);
  //   //   }
  //   // } else {
  //   //   removeItemFromArr(name, setResultados);
  //   //   removeItemFromArr(value, setLista);
  //   //   removeItemFromArr(placeholder, setNames);
  // }
  // }

  // ?
  const clearAllFirstTime = evt => {
    // if (lista.length === 0) {
    //   evt.preventDefault();
    //   setValue('');
    //   setLista([]);
    //   setIdent('');
    //   setResultados([]);
    //   setNames([]);
    // }
  };

  const clearAll = evt => {
    evt.preventDefault();
    setSearchItems([]);
  };

  return (
    <div className="row">
      <div data-wow-delay="0.1s" className="col-12  wow fadeIn">
        <div className="search-input">
          <div id="app">
            <div id="tags" className="row">
              <div className="col-11 etiquetas etpeq">
                {searchItems &&
                  searchItems.map((item, index) => (
                    <Tag key={index} item={item} setSearchItems={setSearchItems} />
                  ))}
              </div>
              {searchItems.length > 0 && (
                <div className="col-1 text-center my-2 m-0 p-0" onClick={clearAll}>
                  <a className="clearBtn text-nowrap">Clear All</a>
                </div>
              )}
            </div>
            <div>
              <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <TextInput value={value} setValue={setValue} />
                <div style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}>
                  <FirstSearchButton setValue={setValue} />
                </div>
              </div>

              {value.trim().length >= 3 && (
                <Dropdown
                  value={value}
                  setValue={setValue}
                  searchItems={searchItems}
                  setSearchItems={setSearchItems}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
