import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import { LegalContent } from '../Components/Legal/LegalContent';
import { LegalNav } from '../Components/Legal/LegalNav';
import Footer from '../Components/Home/Footer';

const LegalNotices = () => {
  ReactGA.send({ hitType: 'pageview', page: '/Legal', title: 'Legal Notices' });

  return (
    <React.Fragment>
      <CommonHeader />
      <LegalNav />
      <LegalContent />
      <Footer />
    </React.Fragment>
  );
};

export default LegalNotices;
