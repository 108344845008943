import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { userAppContext } from '../../Context/Context';
import { Link, useNavigate } from 'react-router-dom';
import getApi from '../../Services/GetApi';
import Reset from '../User/Reset';
import { USER_ROLES_AWS_MAP } from '../Constants';

function validateEmail(email) {
  return /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9\-]+\.[a-zA-Z]{1,5}$/.test(email);
}

function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { isAuthenticated, setIsAuthenticated, setAuthorization, setGroups, setUserApiKey, setUser } =
    userAppContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const togglePassVisibility = () => {
    setIsPassVisible(prevState => !prevState);
  };

  function getApiKeyData(userId) {
    try {
      getApi(userId).then(datos => {
        if (datos?.status === 'OK') {
          setUserApiKey(datos?.payload?.api_key);
        }
      });
    } catch (e) {
      if (e !== 'No current user') {
        // console.log(e);
        console.log('');
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      setIsAnimating(true);
      setTimeout(() => {
        navigate('/');
        setIsAnimating(false);
      }, 100);
    }
  }, [isAuthenticated, navigate]);

  const handleChangeEmail = event => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleChangePassword = event => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 8);
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    try {
      setIsLoading(true); // Set loading to true when the button is clicked
      const user = await Auth.signIn(email, password);
      setUser(user.signInUserSession.idToken.payload);

      if (user) {
        const trialTime = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
        const trialUserInfo = user.signInUserSession.idToken.payload;

        if (
          trialUserInfo['custom:created_at'] !== undefined &&
          trialUserInfo['cognito:groups'].includes('ROLE_TRIAL')
        ) {
          const created_at = Number.parseInt(trialUserInfo['custom:created_at']);
          const extendedTrialTime = trialUserInfo['custom:extended_trial_days']
            ? Number.parseInt(trialUserInfo['custom:extended_trial_days']) * 24 * 60 * 60 * 1000
            : 0;
          const expiryTime = created_at + trialTime + extendedTrialTime;
          // console.log(trialTime, expiryTime);
          const today = new Date().getTime();
          if (expiryTime < today) {
            setIsAuthenticated(false);
            setErrorMessage('Your trial for 7 days has expired.');
            // ... (setUrl logic)
          } else {
            const userId = user.username;
            getApiKeyData(userId);
            setAuthorization(user.signInUserSession.idToken.jwtToken);
            setGroups(
              user.signInUserSession.idToken.payload['cognito:groups'] === undefined
                ? []
                : user.signInUserSession.idToken.payload['cognito:groups'],
            );
            setIsAuthenticated(true);
          }
        } else if (
          trialUserInfo &&
          trialUserInfo['cognito:groups'] &&
          trialUserInfo['cognito:groups'][0] &&
          ![...USER_ROLES_AWS_MAP.keys()].includes(trialUserInfo['cognito:groups'][0])
        ) {
          // user is not in any registered group
          setErrorMessage('User is not in any known group. Please contact it@disgenet.com');
          setAuthorization('');
          setGroups([]);
          setIsAuthenticated(false);
        } else {
          const userId = user.username;
          getApiKeyData(userId);
          setAuthorization(user.signInUserSession.idToken.jwtToken);
          setGroups(
            user.signInUserSession.idToken.payload['cognito:groups'] === undefined
              ? []
              : user.signInUserSession.idToken.payload['cognito:groups'],
          );
          setIsAuthenticated(true);
        }
      }
    } catch (ex) {
      setErrorMessage(ex.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = evt => {
    evt.preventDefault();
    setReset(true);
  };

  return (
    <div>
      <link rel="canonical" href="/login" />
      {reset ? (
        <Reset setReset={setReset} />
      ) : (
        <div className={`container txt-contenido ${isAnimating ? 'fade-out' : ''}`}>
          {isLoading && (
            <div className="loading-overlay">
              <div className="loading" />
            </div>
          )}
          <div className="register-container mb-5">
            <div className="border2-container">
              <div className="border2-container">
                <div className="col-12">
                  <button type="button" className="close" aria-label="Close">
                    <Link to="/" aria-hidden="true">
                      ×
                    </Link>
                  </button>
                  <h3 className="title">SIGN IN</h3>
                  <form className="validate-form" onSubmit={handleSubmit}>
                    <div className="wrap-input100 validate-input" data-validate="Email is required">
                      <label className="label-input100">Email:</label>
                      <input
                        className="input100"
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChangeEmail}
                        required
                      />
                    </div>
                    <div className="wrap-input100 validate-input" data-validate="Password is required">
                      <div className="password-input">
                        <span className="label-input100">Password</span>
                        <span className="eye-icon" onClick={togglePassVisibility}>
                          <i
                            className={`fa ${isPassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <input
                        onChange={handleChangePassword}
                        name="password"
                        id="password"
                        type={`${isPassVisible ? 'text' : 'password'}`}
                        className="input100"
                        placeholder="Password"
                      />
                      <span className="focus-input100 d-flex align-items-center justify-content-end"></span>
                    </div>
                    <a
                      style={{ float: 'right', marginBottom: '20px' }}
                      href="true"
                      onClick={evt => {
                        handleReset(evt);
                      }}
                    >
                      Forgot Password
                    </a>
                    <div className="container-contact100-form-btn">
                      <div className="wrap-contact100-form-btn">
                        <div className="contact100-form-bgbtn" />
                        <button className="contact100-form-btn">Send</button>
                      </div>
                    </div>
                    {errorMessage ? <p className="text-danger text-center">{errorMessage}</p> : null}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
