import React from 'react';
import Modal from 'react-modal';

export default function DownloadConfirmationModal({
  customStyles,
  fileExtension,
  downloadData,
  setShowConfirmationModal
}) {
  return (
    <Modal isOpen={true} ariaHideApp={false} style={customStyles} shouldFocusAfterRender={false}>
      <div className="warning-download-card">
        <section className="warning-download-text">
          <p>The file you are requesting contains more than 50.000 records.</p>
          <p>Please note that the downloads are limited to this number.</p>

          <p>We suggest to use the REST API to perform this request.</p>

          <p className="proceed-text">Do you want to proceed?</p>
        </section>

        <section className="download-actions">
          <button
            type="button"
            className="btn btn-outline-success btn-lg mx-2"
            onClick={() => downloadData(fileExtension)}
          >
            YES
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-lg mx-2"
            onClick={() => setShowConfirmationModal(false)}
          >
            NO
          </button>
        </section>
      </div>
    </Modal>
  );
}
