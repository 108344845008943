import React, { Fragment, useState } from 'react';
import DownloadConfirmationModal from './DownloadConfirmationModal';
import DownloadFilesModal from './DownloadFilesModal';
import buildDownloadEndpoint from '../Utils/buildDownloadEndpoint';
import { userAppContext } from '../../Context/Context';
import fetchDownload from '../../Services/GetDownload';
import { searchContext } from '../../Context/Context';
import { CUSTOM_STYLE } from './../Constants';

const DownloadButton = ({
  extension,
  buttonTitle,
  totalResults,
  columnVisibilityModel,
  titleGrid,
  columns,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { authorization, isAuthenticated, setIsAuthenticated, setGroups, setAuthError, setAuthorization } =
    userAppContext();
  const { view, source, tab, searchItems, isEvidence } = searchContext();

  const handleDownload = (extension, columnVisibilityModel) => {
    if (totalResults > 50000) {
      setShowConfirmationModal(true);
    } else {
      downloadFile(extension, columnVisibilityModel);
    }
  };

  const downloadFile = (extension, columnVisibilityModel) => {
    setShowConfirmationModal(false);
    setShowDownloadModal(true);

    let urlEndpoint = buildDownloadEndpoint({
      extension,
      columnVisibilityModel,
      view,
      source,
      tab,
      searchItems,
      isEvidence,
      titleGrid,
      columns,
    });
    urlEndpoint = !isAuthenticated ? `${urlEndpoint}&skey=unauth` : urlEndpoint;

    fetchDownload(urlEndpoint, authorization).then(blob => {
      // download complete, close loading bar
      setShowDownloadModal(false);
      // format the file name for save
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const downloadFileName = `${view}_${titleGrid}_${tab}_${source}_${searchItems.map(item => item.id).join('-')}`;
      link.setAttribute('download', downloadFileName + `.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  return (
    <>
      {showDownloadModal && <DownloadFilesModal customStyles={CUSTOM_STYLE} />}

      {showConfirmationModal && (
        <DownloadConfirmationModal
          customStyles={CUSTOM_STYLE}
          fileExtension={extension}
          setShowConfirmationModal={setShowConfirmationModal}
          downloadData={handleDownload} // deprecating
          searchItems={searchItems}
        />
      )}
      <button
        type="button"
        className="ml-2 btn btn-info"
        onClick={() => handleDownload(extension, columnVisibilityModel, titleGrid, searchItems)}
      >
        {' '}
        {buttonTitle}&nbsp;
        <i className="fa fa-download" />
      </button>
    </>
  );
};

export default function DownloadButtons({
  searchMethod,
  totalResults,
  columnVisibilityModel,
  titleGrid,
  columns,
}) {
  return (
    <Fragment>
      {searchMethod === 'MENU' && (
        <Fragment>
          <DownloadButton
            extension="tsv"
            buttonTitle="TSV"
            totalResults={totalResults}
            columnVisibilityModel={columnVisibilityModel}
            titleGrid={titleGrid}
            columns={columns}
          />
          <DownloadButton
            extension="xlsx"
            buttonTitle="Excel"
            totalResults={totalResults}
            columnVisibilityModel={columnVisibilityModel}
            titleGrid={titleGrid}
            columns={columns}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
