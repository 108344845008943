import React from 'react';
import Logo from '../Components/Common/Logo';
import { TopRightMenu } from '../Components/NavMenus/TopNavigation';
import Footer from '../Components/Home/Footer';
import ApplyAcademic from '../Components/SignUp/ApplyAcademicPage';

function ApplyAcademicPage() {
  return (
    <React.Fragment>
      <div className="header-content">
        <link rel="canonical" href="/academic-apply" />
        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
      <div className="d-flex justify-content-end mx-4"></div>
      <ApplyAcademic />
      <Footer />
    </React.Fragment>
  );
}

export default ApplyAcademicPage;
