import React, { Fragment, useEffect, useCallback } from 'react';
import { ResponsiveLine } from '@nivo/line';
import GraphTopContent from '../Common/GraphTopContent';
import { NoRecordsFound, NoGraphsAvailable } from '../../NoRecordsFound';
import LoadingSpinner from '../../../UI/LoadingSpinner';

import { getChartAxisLabel } from '../Common/HeatmapLineHelpers';
import { wholeNumberParams } from '../Common/Constants';

import {
  uniqueIdentViewsVariables,
  getLineData,
  getXAxisTitle,
  setLineXMargins,
  lineTheme,
} from './LineHelpers';

import useWindowsDimensions from '../../../../Hooks/UseWindowsDimensions';
import useLineChart from '../../graphHooks/UseLineChart';

import html2canvas from 'html2canvas';

function LineChart({
  source,
  tab,
  keySearch,
  view,
  names,
  chartInfo,
  setChartInfo,
  baseUrl,
  parent,
  lineSearchVariables,
}) {
  // Data necessary for using the custom hook
  // empty array as the initial data for the chart
  const initialChartData = [];

  // current width
  const { width } = useWindowsDimensions();

  // calling the useHTTPSingleFilter hook
  const { chartData, yAxisValue, isPayloadEmpty, isLoading, xMargin, setXMargin } = useLineChart({
    chartInfo,
    initialChartData,
    keySearch,
    source,
    tab,
    baseUrl,
    parent,
    view,
    getLineData,
    width,
    uniqueIdentViewsVariables,
  });

  // check if the chart is empty
  const isChartDataEmpty = chartData.length === 0;

  // set the X axis title
  const originalXAxisTitle = uniqueIdentViewsVariables[parent]?.plottedValues[view];
  const additionalTitleData = {
    numberOfFilters: names.length,
    chartData: chartData,
  };
  const xAxisTitle = getXAxisTitle(originalXAxisTitle, additionalTitleData);

  // create a custom tooltip for each point in the graph
  const lineTooltip = useCallback(
    ({ point }) => {
      return (
        <div className="custom-tooltip-heatmap">
          <span>
            <em>{point.data.x}</em>
          </span>
          <br />
          <span>
            <em>{chartInfo.variableName}:</em> <strong>{point.data.y}</strong>
          </span>
        </div>
      );
    },
    [chartInfo],
  );

  // adjust the X margins when the width changes
  useEffect(() => {
    if (chartData.length > 0) {
      const totalResults = chartData[0].data.length;
      setXMargin(() => setLineXMargins(width, totalResults));
    }
  }, [width]);

  function downloadJSON() {
    const JSONcomplete = JSON.stringify(chartData);
    const jsonURL = `data:text/json;charset=utf-8,${encodeURIComponent(JSONcomplete)}`;

    const link = document.createElement('a');
    link.href = jsonURL;
    link.download = 'HEATMAP.JSON';
    link.click();
  }

  const downloadPNG = () => {
    const chartContainer = document.querySelector('.chart-container-75');
    if (chartContainer) {
      html2canvas(chartContainer).then(function (canvas) {
        const link = document.createElement('a');
        link.download = 'chart.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };

  return (
    <section>
      {isLoading && (
        <div className="row">
          {' '}
          <LoadingSpinner />
        </div>
      )}
      {!isPayloadEmpty && !isLoading && (
        <Fragment>
          {!isChartDataEmpty && (
            <Fragment>
              <GraphTopContent
                graphSearchVariables={lineSearchVariables}
                chartInfo={chartInfo}
                setChartInfo={setChartInfo}
                parent={parent}
                resultsNumber={20}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
                <button
                  className="graph-btn-download py-1 px-2 rounded inline-flex items-center"
                  onClick={downloadPNG}
                >
                  <svg
                    class="fill-current w-3 h-3 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span class="text-sm">Download PNG</span>
                </button>
                <button
                  className="graph-btn-download py-1 px-2 rounded inline-flex items-center ml-2"
                  onClick={() => downloadJSON()}
                >
                  <svg
                    class="fill-current w-3 h-3 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  Download JSON
                </button>
              </div>

              <div className={`row chart-container-75`}>
                <ResponsiveLine
                  data={chartData}
                  margin={{
                    top: 80,
                    right: xMargin,
                    bottom: 40,
                    left: xMargin,
                  }}
                  theme={lineTheme}
                  width={width * 0.9}
                  height={700}
                  colors={() => '#336575'}
                  lineWidth={0}
                  tooltip={lineTooltip}
                  useMesh={true}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: yAxisValue.min,
                    max: yAxisValue.max,
                    stacked: false,
                    reverse: false,
                  }}
                  axisTop={{
                    legend: `${xAxisTitle}`,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    renderTick: () => {
                      return (
                        <g>
                          <text>{``}</text>
                        </g>
                      );
                    },
                  }}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 15,
                    tickRotation: 90,
                    renderTick: tickProperties => {
                      const labelClassNames = 'axisLegend';
                      const sliceValue = 4;
                      const doesTickExist = false;
                      return getChartAxisLabel({
                        ...tickProperties,
                        labelClassNames,
                        sliceValue,
                        doesTickExist,
                      });
                    },
                  }}
                  axisLeft={{
                    format: value => {
                      if (wholeNumberParams.includes(chartInfo.variable) && value.toString().includes('.')) {
                        return '';
                      }
                      return value;
                    },
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: `${chartInfo.variableFullName}`,
                    legendOffset: -80,
                    legendPosition: 'middle',
                  }}
                  pointSize={15}
                  pointColor={'rgb(10,16, 79)'}
                  pointBorderWidth={3}
                  pointBorderColor={'rgb(0,15,45)'}
                  pointLabelYOffset={-12}
                />
              </div>
            </Fragment>
          )}
          {isChartDataEmpty && (
            <Fragment>
              <GraphTopContent
                graphSearchVariables={lineSearchVariables}
                chartInfo={chartInfo}
                setChartInfo={setChartInfo}
                parent={parent}
                resultsNumber={0}
              />
              <NoGraphsAvailable />
            </Fragment>
          )}
        </Fragment>
      )}
      {isPayloadEmpty && !isLoading && (
        <div className="row">
          {' '}
          <NoRecordsFound />
        </div>
      )}
    </section>
  );
}

export default LineChart;
