import React from 'react';

class ActivationContent extends React.Component {
  copyEmail = () => {
    const email = 'info@disgenet.com';
    navigator.clipboard.writeText(email);
  };
  ActivationContent;
  render() {
    return (
      <section>
        <style>{`
          .copy-info {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            text-decoration: underline;
            color: rgb(190,0,128);
          }
          .a-search{
            color: rgb(190,0,128);
          }
        `}</style>
        <div className="container">
          <div data-wow-delay="0.3s" className="p-4">
            <h2>Free Trial Activated</h2>
            <br />
            <h5>You now have access to the DISGENET Advanced plan.</h5>
            <h5>Remember that your trial period will end in 7 days.</h5>
            <br />
            <h5>
              Start searching for the most recent and relevant data on disease genomics by{' '}
              <a className="a-search" href="/">
                clicking here.
              </a>
            </h5>
            <br />
            <h6>
              For more information, contact us at{' '}
              <span className="copy-info" title="copy" onClick={this.copyEmail}>
                info@disgenet.com
              </span>
            </h6>
          </div>
        </div>
      </section>
    );
  }
}

export default ActivationContent;
