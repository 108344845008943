import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UseInput from '../../Hooks/UseInput';
import { validateEmail, validateNotEmpty } from './ValidationLogic';

import emailjs from '@emailjs/browser';
import { emailJSPublicKey } from '../../Constants/Constants';

export default function ContactContent() {
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [formError, setFormError] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState('');

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);

  const {
    value: name,
    isInputValid: isNameValid,
    isInputInvalid: isNameInvalid,
    inputClassName: nameClassName,
    valueChangeHandler: nameChangeHandler,
    valueBlurHandler: nameBlurHandler,
    resetInput: resetNameInput,
  } = UseInput(validateNotEmpty);

  const {
    value: email,
    isInputValid: isEmailValid,
    isInputInvalid: isEmailInvalid,
    inputClassName: emailClassName,
    valueChangeHandler: emailChangeHandler,
    valueBlurHandler: emailBlurHandler,
    resetInput: resetEmailInput,
  } = UseInput(validateEmail);

  const {
    value: company,
    isInputValid: isCompanyValid,
    isInputInvalid: isCompanyInvalid,
    inputClassName: companyClassName,
    valueChangeHandler: companyChangeHandler,
    valueBlurHandler: companyBlurHandler,
    resetInput: resetCompanyInput,
  } = UseInput(validateNotEmpty);

  const {
    value: message,
    isInputValid: isMessageValid,
    isInputInvalid: isMessageInvalid,
    inputClassName: messageClassName,
    valueChangeHandler: messageChangeHandler,
    valueBlurHandler: messageBlurHandler,
    resetInput: resetMessageInput,
  } = UseInput(validateNotEmpty);

  function onChangePolicyHandler() {
    setIsPolicyAccepted(prevState => !prevState);
  }

  function resetForm() {
    resetNameInput();
    resetEmailInput();
    resetCompanyInput();
    resetMessageInput();
    setIsPolicyAccepted(false);
    setFormError(null);

    setSelectedSubject('');
    setIsPopupVisible(false);
    setIsSendButtonDisabled(false);
  }

  function checkFormValidity() {
    const inputsValidity = [isNameValid, isEmailValid, isCompanyValid, isMessageValid];
    return (
      inputsValidity.every(isInputValid => isInputValid) &&
      selectedSubject !== 'I would like to apply for an academic license'
    );
  }

  async function submitMessageHandler(event) {
    event.preventDefault();
    const messageInfo = {
      name: name,
      email: email,
      organization: company,
      message: message,
      subject: selectedSubject,
    };
    if (checkFormValidity()) {
      try {
        // todo: retire the emailjs account for something in current infra
        const response = await emailjs.send(
          'service_imqzseq',
          'template_thbdt1d',
          messageInfo,
          emailJSPublicKey,
        );
        resetForm();
        setEmailSentMessage('Your email was successfully sent!');
      } catch (e) {
        setEmailSentMessage(
          'There was an error with email submission. Please try again or contact us by email at info@disgenet.com',
        );
      }
    } else {
      setFormError('Validation failed. Check your inputs and try again');
    }
  }

  function handleSubjectChange(e) {
    const value = e.target.value;
    setSelectedSubject(value);
    if (value === 'I would like to apply for an academic license') {
      setIsPopupVisible(true);
    } else {
      setIsPopupVisible(false);
    }
  }

  return (
    <section>
      <link rel="canonical" href="/Contact-us" />
      <div className="container">
        <div className="row">
          <div data-wow-delay="" className="col-12 p-4">
            <h2 className="gradient-color">Contact Us</h2>
            <div className="row d-flex">
              <div className="col-sm-12 col-md-12 col-lg-6 col-md-6">
                <p className="mb-5">
                  For more information on DISGENET, or for inquiries about our licensing models, please fill
                  out the form below. We’d love to hear from you!
                </p>
                <form className="validate-form" onSubmit={submitMessageHandler}>
                  <div>
                    {emailSentMessage ? <p className="sent-message-info">{emailSentMessage}</p> : null}
                  </div>
                  <div className={`wrap-input100`} data-validate="Name is required">
                    <span className={`label-input100 ${nameClassName}`}>Name *</span>
                    <input
                      className="input100"
                      type="text"
                      name="name"
                      placeholder="Name..."
                      onChange={nameChangeHandler}
                      onBlur={nameBlurHandler}
                      value={name}
                    />
                    <span className="focus-input100" />
                  </div>
                  <div>
                    {isNameInvalid && <p className="input-validation-error">Please enter your name</p>}
                  </div>
                  <div className="wrap-input100" data-validate="Valid email is required: ex@abc.xyz">
                    <span className={`label-input100 ${emailClassName}`}>Email *</span>
                    <input
                      className="input100"
                      type="text"
                      name="email"
                      placeholder="Email address..."
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                      value={email}
                    />
                    <span className="focus-input100" />
                  </div>
                  <div>
                    {isEmailInvalid && (
                      <p className="input-validation-error">Please enter a valid email: name@example.com</p>
                    )}
                  </div>
                  <div className="wrap-input100" data-validate="Name is required">
                    <span className={`label-input100 ${companyClassName}`}>Company *</span>
                    <input
                      className="input100"
                      type="text"
                      name="company"
                      placeholder="Company..."
                      onChange={companyChangeHandler}
                      onBlur={companyBlurHandler}
                      value={company}
                    />
                    <span className="focus-input100" />
                  </div>
                  <div>
                    {isCompanyInvalid && (
                      <p className="input-validation-error">Please enter your company's name</p>
                    )}
                  </div>
                  <div className="wrap-input100">
                    <span className="label-input100">Subject *</span>
                    <select
                      className="input100"
                      name="subject"
                      onChange={handleSubjectChange}
                      value={selectedSubject}
                    >
                      <option value="">...</option>
                      <option value="I would like to apply for an academic license">
                        I would like to apply for an academic license
                      </option>
                      <option value="I would like more information about the product or license">
                        I would like more information about the product or license
                      </option>
                      <option value="I would like to provide feedback">
                        I would like to provide feedback
                      </option>
                      <option value="I would like to contact the support team">
                        I would like to contact the support team
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className="wrap-input100" data-validate="Message is required">
                    <span className={`label-input100 ${messageClassName}`}>Message *</span>
                    <textarea
                      className="input100"
                      name="message"
                      placeholder="Questions/Comments..."
                      onChange={messageChangeHandler}
                      onBlur={messageBlurHandler}
                      value={message}
                    />
                    <span className="focus-input100" />
                  </div>
                  {isMessageInvalid && (
                    <p className="input-validation-error">Please enter your question / comments</p>
                  )}
                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      autoFocus
                      id="ppolicyCheck"
                      name="ppolicy"
                      onChange={onChangePolicyHandler}
                      checked={isPolicyAccepted}
                    />
                    <input type="hidden" name="_ppolicy" defaultValue="on" /> 
                    <label className="form-check-label" htmlFor="ppolicyCheck">
                      I have carefully read and accepted the{' '}
                      <Link to="/Privacy-policy">DISGENET privacy policy</Link>
                    </label>
                  </div>

                  <div>
                    {formError && <p className="form-error">{formError}</p>}
                    {emailSentMessage && <p className="form-sucess">{emailSentMessage}</p>}
                  </div>
                  <div className="container-contact100-form-btn">
                    <div className="wrap-contact100-form-btn">
                      <div className="contact100-form-bgbtn" />
                      <button
                        type="submit"
                        className="contact100-form-btn"
                        disabled={
                          !isPolicyAccepted ||
                          selectedSubject === 'I would like to apply for an academic license'
                        }
                        title={
                          selectedSubject ? 'apply for an academic license on the academic apply page.' : ''
                        }
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mapita">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.3454629823714!2d2.162852784754208!3d41.38830339729827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2f2528cd1a7%3A0xcab870e40ece5da8!2sRambla de Catalunya%2C 14%2C 08007 Barcelona!5e0!3m2!1ses-419!2ses!4v1699352156342!5m2!1ses-419!2ses"
                  width="700"
                  height="700"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  allowFullScreen
                  tabIndex={0}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-contact">
          <div className="popup-inner-contact">
            <h3 className="p-2">IMPORTANT</h3>
            <h6 className="pb-2">
              To apply for an academic license, please go to the{' '}
              <Link to="/academic-apply" onClick={() => setIsPopupVisible(false)}>
                academic apply page
              </Link>
              .
            </h6>
            <button onClick={() => setIsPopupVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </section>
  );
}
